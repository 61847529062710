import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tiles from "../../common/tiles";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { profileSettings } from "../../../states/actions/profileSettings";
import LoadingScreen from "../../common/LoadingScreen";
import Sidebar from "./sections/sidebar";

export default function UserDashboard() {
  const isLoading = useSelector((state) => state.profileSettings.isLoading);
  const member_row = useSelector((state) => state.profileSettings.verify);
  const dispatch = useDispatch();
  useDocumentTitle("Dashboard - Loftus");
  useEffect(() => {
    dispatch(profileSettings());
  }, []);

  if (isLoading) return <LoadingScreen />;
  const member = member_row.settings.member;
  const dashboard_tiles = [
    {
      id: 1,
      image: "/images/dashboard/icons/1.png",
      title: "Properties",
      count: member?.properties_count,
      link: "/properties",
    },
    {
      id: 2,
      image: "/images/dashboard/icons/2.png",
      title: "Listings",
      count: member?.listings_count,
      link: "/listing",
    },
    {
      id: 3,
      image: "/images/dashboard/icons/3.png",
      title: "Favorites",
      count: 5,
      link: "/favorites",
    },
    {
      id: 4,
      image: "/images/dashboard/icons/earning.png",
      title: "Payments",
      count: member?.payments_count,
      link: "/payments",
    },
    {
      id: 5,
      image: "/images/dashboard/icons/5.png",
      title: "Offers",
      count: member?.offers_count,
      link: "/offers",
    },
    {
      id: 6,
      image: "/images/dashboard/icons/6.png",
      title: "Leases",
      count: member?.leases_count,
      link: "/leases",
    },
    {
      id: 7,
      image: "/images/dashboard/icons/7.png",
      title: "Service Requests",
      count: 16,
      link: "/service-requests",
    },
  ];
  // userVerification(member)
  return (
    <div index="" className="main_dashboard">
      <section className="dashboard">
        <div className="contain">
          <div className="flex dash_intro_flex">
            <div className="colL">
              <Sidebar active="dashboard" />
            </div>
            <div className="colR">
              <Tiles data={dashboard_tiles} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

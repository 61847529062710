import { useParams } from "react-router-dom";
import Breadcrumb from "../dashboard/sections/breadcrumb";

import {
  fetchPaymentDetails,
  savePayment,
} from "../../../states/actions/payments";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { useEffect } from "react";
import Error from "../Error";
import PaymentForm from "./payments/payment-form";
import { Elements } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import PaymentTotalDetails from "./payments/payment-total-details";
import Text from "../../common/Text";
import PaymentPropertyBlk from "./payments/payment-property-blk";
import { format_date } from "../../../helpers/api";
export default function PaymentSingle({ is_landlord = false }) {
  const { payment_id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.payments.payment_details_content);
  const mem_payment_methods = useSelector(
    (state) => state.payments.mem_payment_methods
  );
  const mem_payment_methods_ach = useSelector(
    (state) => state.payments.mem_payment_methods_ach
  );
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const payment_row = data?.payment_row;
  const lease_row = data?.lease_row;
  const member = data?.member;
  // const mem_payment_methods = data?.payment_methods;
  const property_obj = data?.property_obj;
  const isLoading = useSelector(
    (state) => state.payments.isPaymentDetailsLoading
  );
  const isPaymentFormProcessing = useSelector(
    (state) => state.payments.isPaymentFormProcessing
  );
  useEffect(() => {
    dispatch(fetchPaymentDetails(payment_id, is_landlord));
  }, []);
  const handleSavePayment = (frmData) => {
    const newFrmdata = {
      ...frmData,
      total_amount: parseFloat(frmData?.total_fee),
    };
    dispatch(savePayment(newFrmdata));
  };
  useDocumentTitle("Payment# " + payment_id + " - Loftus");
  if (isLoading || !siteSettings) return <LoadingScreen />;
  if (
    (data === undefined ||
      lease_row === undefined ||
      lease_row === null ||
      lease_row?.id <= 0 ||
      lease_row === "") &&
    data?.status !== 1
  ) {
    return <Error />;
  }
  let stripePromise = "";
  if (
    siteSettings.site_stripe_type === 1 ||
    siteSettings.site_stripe_type === "1"
  ) {
    stripePromise = loadStripe(siteSettings?.site_stripe_testing_api_key);
  } else if (
    siteSettings.site_stripe_type === 0 ||
    siteSettings.site_stripe_type === "0"
  ) {
    stripePromise = loadStripe(siteSettings?.site_stripe_live_api_key);
  }
  return (
    <main index class="main_dashboard">
      <section class="dashboard">
        <div class="contain">
          <Breadcrumb
            page_name={"Payment# " + payment_id}
            parent_name="Payments"
            parent_link="/payments"
          />
          <div class="page_body">
            <div class="offer_dash_detail_page">
              <div class="payment-new-info" data-popup="new-pay">
                <div class="tableDv">
                  <div class="tableCell">
                    <div class="_inner">
                      {is_landlord !== true ? (
                        <div className="flex lease_payment_flex">
                          <PaymentPropertyBlk lease_row={lease_row} />
                          {parseInt(lease_row?.expired) !== 1 ? (
                            <div className="colR">
                              <div class="blk blockLst cell_block_cell paymentBlkFrm">
                                {
                                  // lease_row?.lease_started === true ?
                                  <Elements stripe={stripePromise}>
                                    <PaymentForm
                                      lease_row={lease_row}
                                      isPaymentFormProcessing={
                                        isPaymentFormProcessing
                                      }
                                      handleSavePayment={handleSavePayment}
                                      mem_payment_methods={mem_payment_methods}
                                      member={member}
                                      mem_payment_methods_ach={
                                        mem_payment_methods_ach
                                      }
                                      siteSettings={siteSettings}
                                    />
                                  </Elements>
                                  // :
                                  // <div className="alert alert-danger">You can pay once lease will be started and lease will be started on {format_date(lease_row?.start_date)}</div>
                                }
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      <div class="gap_line_divide"></div>
                      <h4 class="ofer_heading custom_ofer_heading">
                        Payment History
                      </h4>
                      <PaymentTotalDetails lease_row={lease_row} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

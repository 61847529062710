import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  getPropertyOffers,
  getBranchProperties,
} from "../../../states/actions/leases";
import { useSelector, useDispatch } from "react-redux";
import OfferBlk from "./offerBlk";
import ToggleButton from "./toggleBtn";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Toaster, toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { current_date } from "../../../helpers/api";
import moment from "moment-timezone";

export default function AddLeasePopup({
  closePopup,
  leases_properties,
  branches,
  mem_type,
}) {
  const [branchPropertiesArr, setBranchPropertiesArr] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const dispatch = useDispatch();
  const offers = useSelector((state) => state.leases.offers);
  const listing_row = useSelector((state) => state.leases.listing_row);
  const isOfferLoading = useSelector((state) => state.leases.isOfferLoading);
  const isBranchLoading = useSelector((state) => state.leases.isBranchLoading);
  const branch_properties = useSelector(
    (state) => state.leases.branch_properties
  );
  useEffect(() => {
    setBranchPropertiesArr(branch_properties);
  }, [branch_properties]);

  const changeProperty = (selectedOption) => {
    const property_id = selectedOption?.value;
    dispatch(getPropertyOffers({ property_id: property_id, type: "property" }));
  };
  const changeBranchProperty = (selectedOption) => {
    const property_id = selectedOption?.value;
    dispatch(
      getPropertyOffers({
        property_id: property_id,
        type: "branch",
        branch_id: watchAllFields?.branch,
      })
    );
  };
  const changeBranch = (selectedOption) => {
    const branch_id = selectedOption?.value;
    dispatch(getBranchProperties({ branch_id: branch_id, type: "branch" }));
  };
  const [selectedOffer, setsSlectedOffer] = useState([]);
  const handleOfferSelection = (item) => {
    const itemIndex = selectedOffer.findIndex(
      (existingItem) => existingItem === item
    );

    if (itemIndex !== -1) {
      // Item exists, so remove it
      const updatedItems = [
        ...selectedOffer.slice(0, itemIndex),
        ...selectedOffer.slice(itemIndex + 1),
      ];
      setsSlectedOffer(updatedItems);
    } else {
      // Item does not exist, so add it
      const updatedItems = [...selectedOffer, item];
      setsSlectedOffer(updatedItems);
    }
  };
  const [tenants, setTenants] = useState({
    tenants: [],
  });
  const handleAddTenant = () => {
    setTenants({
      ...tenants,
      tenants: tenants.tenants.concat([
        { randomKey: Math.random(), name: "", email: "" },
      ]),
    });
  };

  function handleRemoveTenant(idx, e) {
    e.preventDefault();
    setTenants({
      ...tenants,
      tenants: tenants.tenants
        .slice(0, idx)
        .concat(tenants.tenants.slice(idx + 1, tenants.tenants.length)),
    });
  }
  const handleTenantChange = (idx, evt) => {
    const newTenants = tenants.tenants.map((tenant, sidx) => {
      if (idx !== sidx) return tenant;
      // return { ...tenant, [evt.target.name]: evt.target.value };
      if (evt.target.name === "email") {
        const inputValue = evt.target.value;
        const cleanValue = inputValue.replace(/\s/g, ""); // Remove all spaces
        evt.target.value = cleanValue;
        return { ...tenant, [evt.target.name]: evt.target.value };
      } else {
        return { ...tenant, [evt.target.name]: evt.target.value };
      }
    });
    setTenants({ ...tenants, tenants: newTenants });
  };
  const getTenantsFromOffers = () => {
    const tenantsArray = [];

    // Iterate through the original array and push tenants into the new array
    selectedOffer?.forEach((item) => {
      tenantsArray.push(...item.tenants);
    });
    return tenantsArray;
  };
  function haveSameEmails(array1, array2) {
    if (array1?.length > 0 && array2?.length > 0) {
      const emails1 = array1.map((item) => item.email);
      const emails2 = array2.map((item) => item.email);
      for (const email of emails1) {
        if (emails2.includes(email)) {
          return email; // At least one email is the same
        }
      }
      return false;
    }
    return false;
    // No matching emails found
  }
  const checkTenantsSameEmails = (arrayOfObjects) => {
    const uniqueEmails = new Set();
    let emailExist = false;
    for (const obj of arrayOfObjects) {
      const email = obj.email;
      if (uniqueEmails.has(email)) {
        emailExist = email;
        break;
      }
      uniqueEmails.add(email);
    }
    if (emailExist) {
      toast.error(emailExist + " can not be duplicated!");
      return;
    }
  };
  const onSubmit = (data) => {
    // const tenants = [];
    if (
      data?.property === undefined ||
      data?.property === null ||
      data?.property === ""
    ) {
      toast.error("Please select property to continue!");
      return;
    }
    if (selectedOffer?.length <= 0 && tenants?.tenants?.length <= 0) {
      toast.error("Either select an offer or add tenants continue!");
      return;
    }
    if (selectedOffer?.length > 0) {
      const currentDate = moment().tz("America/New_York").format("YYYY-MM-DD");
      selectedOffer?.forEach((item) => {
        const startDate = moment(item?.start_date)
          .tz("America/New_York")
          .format("YYYY-MM-DD");
        // const startDate = moment(item.start_date).tz('America/New_York').format('YYYY-MM-DD');
        if (startDate < currentDate) {
          toast.error(
            `Error: Start date for Offer#: FV${item?.id} should be a future date.`
          );
          return;
        }
        // If the start_date is valid, you can proceed with your submission logic
      });
    }
    const offerTenants = getTenantsFromOffers();
    checkTenantsSameEmails(offerTenants);
    const emailExist = haveSameEmails(offerTenants, tenants?.tenants);
    if (
      emailExist !== false &&
      emailExist !== "" &&
      emailExist !== undefined &&
      emailExist !== null
    ) {
      toast.error(emailExist + " can not be duplicated!");
      return;
    }
    const newTenants = [...offerTenants, ...tenants?.tenants];
    let tenants_arr = JSON.stringify(newTenants);
    const newData = {
      ...data,
      tenants: tenants_arr,
      listing_id: listing_row?.id,
    };
    // ; return;
    const encodedObject = encodeURIComponent(JSON.stringify(newData));
    // Object.entries(newData)?.map(([key, value]) => (
    //     value !== undefined && value !== null && value !== '' && value !== false ?
    //         searchParams.set(key, value)
    //         :
    //         searchParams.delete(key)
    // ))
    searchParams.set("lease_data", encodedObject);
    navigate(`/create-lease?${searchParams.toString()}`);
  };

  return (
    <>
      {/* <Toaster position="top-center" /> */}
      <section
        className="popup popup_for_lease"
        data-popup="choose-lease-property"
      >
        <div className="tableDv">
          <div className="tableCell">
            <div className="_inner">
              {/* <Toaster position="top-center" /> */}
              <div className="crosBtn" onClick={() => closePopup()}></div>
              <h4>Create Lease for:</h4>
              <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
                {/* <div className="txtGrp popup_toggle">
                                <h5>Do you want to add tenants manually?</h5>
                                <ToggleButton register={register} watchAllFields={watchAllFields} />
                            </div> */}
                {mem_type === "company" ? (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="txtGrp popup_select_property">
                        <h6>Select Branch</h6>
                        <Controller
                          {...register("branch", { required: "Required" })}
                          name="branch"
                          id="branch"
                          className=""
                          control={control}
                          defaultValue={watchAllFields?.branch}
                          render={({
                            field: { onChange, value, ref, name },
                          }) => (
                            <>
                              <Select
                                options={branches}
                                onChange={(val) => {
                                  onChange(val.value);
                                  setValue("branch", val.value);
                                  changeBranch(val);
                                }}
                                label="Select Branch"
                                defaultValue={branches?.filter(function (
                                  option
                                ) {
                                  return option.value === value;
                                })}
                              />
                            </>
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="branch"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="txtGrp popup_select_property">
                        <h6>Select Property</h6>
                        <Controller
                          {...register("property", { required: "Required" })}
                          name="property"
                          id="property"
                          className=""
                          control={control}
                          defaultValue={watchAllFields?.property}
                          render={({
                            field: { onChange, value, ref, name },
                          }) => (
                            <>
                              <Select
                                options={branchPropertiesArr}
                                onChange={(val) => {
                                  onChange(val.value);
                                  setValue("property", val.value);
                                  changeBranchProperty(val);
                                }}
                                label="Select Property"
                                defaultValue={branchPropertiesArr?.filter(
                                  function (option) {
                                    return option.value === value;
                                  }
                                )}
                              />
                            </>
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="property"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="txtGrp popup_select_property">
                    <h6>Select Property</h6>
                    <Controller
                      {...register("property", { required: "Required" })}
                      name="property"
                      id="property"
                      className=""
                      control={control}
                      defaultValue={watchAllFields?.property}
                      render={({ field: { onChange, value, ref, name } }) => (
                        <>
                          <Select
                            options={leases_properties}
                            onChange={(val) => {
                              onChange(val.value);
                              setValue("property", val.value);
                              changeProperty(val);
                            }}
                            label="Select Property"
                            defaultValue={leases_properties?.filter(function (
                              option
                            ) {
                              return option.value === value;
                            })}
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="property"
                      render={({ message }) => (
                        <p className="error">
                          <i className="fi-warning"></i> {message}
                        </p>
                      )}
                    />
                  </div>
                )}
                {isOfferLoading || isBranchLoading ? (
                  <div id="leadingMainContainer" className="leaseLoading">
                    <div className="loading-container">
                      <div className="loading"></div>
                      <div id="loading-text">loading</div>
                    </div>
                  </div>
                ) : offers?.length > 0 ? (
                  <div className="txtGrp popup_select_property">
                    <h6>Select Offer</h6>
                    <div className="offer_leases_pop_outer">
                      <ul>
                        {offers?.map((offer, index) => {
                          return (
                            <OfferBlk
                              item={offer}
                              key={index}
                              selectedOffer={selectedOffer}
                              handleOfferSelection={handleOfferSelection}
                            />
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="alert alert-danger">
                    No offer(s) available!
                  </div>
                )}

                <div className="txtGrp popup_select_property">
                  <div className="oRLine">
                    <span>OR</span>
                  </div>
                  <h4>External Invite</h4>
                  {tenants?.tenants?.map((tenant, index) => {
                    return (
                      <div className="new_blk_cost_add" key={tenant?.randomKey}>
                        <div className="inner_blk flex">
                          <div
                            className="closeBtn"
                            onClick={(e) => handleRemoveTenant(index, e)}
                          ></div>
                          <div className="inner_colL">
                            <div className="">
                              <input
                                type="text"
                                name="name"
                                id="name"
                                className="txtBox"
                                placeholder="Name"
                                onChange={(e) => handleTenantChange(index, e)}
                                defaultValue={tenant.name}
                              />
                            </div>
                          </div>
                          <div className="inner_colR">
                            <div className="relative">
                              <input
                                type="text"
                                name="email"
                                id="email"
                                className="txtBox"
                                placeholder="Email"
                                onChange={(e) => handleTenantChange(index, e)}
                                defaultValue={tenant.email.split(" ").join("")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div
                    className="add_more_tenats auto_plus_mrgn"
                    onClick={handleAddTenant}
                  >
                    <i className="fa fa-plus"></i>
                  </div>
                </div>

                <div className="bTn justify_btn_center">
                  <button type="submit" className="webBtn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

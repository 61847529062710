import React from "react";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import { useParams } from "react-router-dom";

import {
  getPaymentMethodDetails,
  saveCard,
} from "../../../states/actions/payments";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { useEffect } from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentMethodForm from "./payment-methods-sections/form";

export default function EditCardDetails() {
  const { payment_method_id } = useParams();
  const data = useSelector((state) => state.payments.content);
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isLoading = useSelector((state) => state.payments.isLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPaymentMethodDetails({ payment_method_id: payment_method_id }));
  }, []);
  useDocumentTitle("Edit Card Details - Loftus");
  if (isLoading || !siteSettings) return <LoadingScreen />;
  let stripePromise = "";
  if (
    siteSettings.site_stripe_type === 1 ||
    siteSettings.site_stripe_type === "1"
  ) {
    stripePromise = loadStripe(siteSettings?.site_stripe_testing_api_key);
  } else if (
    siteSettings.site_stripe_type === 0 ||
    siteSettings.site_stripe_type === "0"
  ) {
    stripePromise = loadStripe(siteSettings?.site_stripe_live_api_key);
  }
  const countries = siteSettings.countries;
  const states = siteSettings.states;
  return (
    <>
      <main index className="main_dashboard">
        <section className="dashboard">
          <div className="contain">
            <Breadcrumb
              page_name={"Edit Card Details"}
              parent_name="Payment Methods"
              parent_link="/payment-methods"
            />
            <div className="page_body">
              <div className="dash_form_blk small_form_payment">
                <Elements stripe={stripePromise}>
                  <PaymentMethodForm
                    saveCard={saveCard}
                    member={data?.member}
                    payment_method_row={data?.payment_method_row}
                    countries={countries}
                    states={states}
                    payment_method_id={payment_method_id}
                  />
                </Elements>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Breadcrumb from "./sections/breadcrumb";
import { useParams } from "react-router-dom";
import MaintenanceForm from "./maintenance_team/form";
import { Link } from "react-router-dom";

import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import {
  fetchSingleMaintenanceStaff,
  postStaffPerson,
} from "../../../states/actions/fetchMaintenanceStaff";
import { consoleLog } from "../../../helpers/api";
import DirectInvitePopup from "./maintenance_profile/direct-invite-popup";
import InvitePopup from "./maintenance_profile/invite-popup";
import http from "../../../helpers/http";
import * as helpers from "../../../helpers/api";
import toast from "react-hot-toast";
import Text from "../../common/Text";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import StartChat from "./maintenance-requests/startChat";
import DetailPopup from "./maintenance_profile/detail-popup";
export default function AddMaintenancePerson() {
  const [isMoreHovered, setIsMoreHovered] = useState(false);
  const [popup, setPopup] = useState({ item: null, show: false });
  const [detailPopup, setDetailPopup] = useState(null);
  const openDetailPopup = (item) => {
    setDetailPopup(item);
  };
  const closeDetailPopup = () => {
    setDetailPopup(null);
  };
  const closePopup = () => {
    setPopup({ item: null, show: false });
  };
  const openPopup = (item) => {
    setPopup({ item: item, show: true });
  };

  const [invitePopup, setInvitePopup] = useState(false);
  const closeInvitePopup = () => {
    setInvitePopup(false);
  };
  const openInvitePopup = () => {
    setInvitePopup(!invitePopup);
  };

  const { person_id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchMaintenanceStaff.content);

  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isLoading = useSelector(
    (state) => state.fetchMaintenanceStaff.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchMaintenanceStaff.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchSingleMaintenanceStaff(person_id, "no_type", "no_req_type"));
  }, []);
  const handleSavePerson = (frmData) => {
    dispatch(postStaffPerson(frmData, person_id));
  };
  const [formData, setFormData] = useState({ city_id: null, state_id: null,category_id:null });
  const fetchCities = async (stateId) => {
    try {
      setCityStateLoading(true);
      const response = await http.post(
        `get-state-cities/${stateId}`,
        helpers.doObjToFormData({ state: stateId })
      );
      return response.data.cities;
    } catch (error) {
      setCityStateLoading(false);
      helpers.consoleLog(error);
      return [];
    }
  };
  const [cityStateUsers, setCityStateUsers] = useState([]);
  const [cityStateUserProperties, setCityStateUserProperties] = useState([]);
  const fetchUsersByCityOrState = async (type, value) => {
    setCityStateUsers([]);
    let form_data = { ...formData, [type]: value };
    if(type==='state_id'){
      form_data = { ...formData, city_id:null, [type]: value };
    }
    console.log(form_data)
    try {
      setCityStateLoading(true);
      const response = await http.post(
        `get-users-by-state-city`,
        helpers.doObjToFormData(form_data)
      );
      setCityStateLoading(false);
      if (response.data.status) {
        setCityStateUsers(response?.data?.users);
        setCityStateUserProperties(response?.data?.properties_arr);
      }
    } catch (error) {
      setCityStateLoading(false);
      helpers.consoleLog(error);
    }
  };
  const [cityStateLoading, setCityStateLoading] = useState(false);
  const [stateCities, setStateCities] = useState([]);

  const handleSearchCityState = async (value, type) => {
    // Update form data for the selected state or city

    if (type === "state_id") {
      setStateCities([]);
      setFormData({ [type]: value?.value, city_id: null });
      document.getElementById("city_id").value=""
      // fetchCities(value?.value).then((cities) => {
      //   setStateCities(cities);
      // });
    } else {
      setFormData({ ...formData,[type]: value?.value });
    }

    // Fetch users based on city or state selection
    fetchUsersByCityOrState(type, value?.value);
  };

  useDocumentTitle(
    person_id != undefined && person_id != null
      ? "Edit Service Staff - Loftus"
      : "Add Service Staff - Loftus"
  );
  if (isLoading) return <LoadingScreen />;

  const properties = cityStateUserProperties;
  const staff_row = data?.staff_row;
  const direct_properties = data?.properties;
  const states = siteSettings?.states;
const categories=[
  { value: "", label: "None" },
  { value: "handyman", label: "Handyman" },
  { value: "electrician", label: "Electrician" },
  { value: "plumber", label: "Plumber" },
  { value: "hvac", label: "HVAC" },
  { value: "painter", label: "Painter" },
  { value: "locksmith", label: "Locksmith" },
  { value: "landscaper", label: "Landscaper" },
  { value: "pest-control", label: "Pest Control" },
]
  return (
    <>
      <main index className="main_dashboard">
        <section className="dashboard">
          <div className="contain">
            <Breadcrumb
              page_name={
                person_id != undefined && person_id != null
                  ? "Edit Service Staff "
                  : "Add Service Staff"
              }
              parent_name="Service Staff"
              parent_link="/service-staff"
            />
            <div className="page_body">
              <div className="maintenance_form dash_form_blk add-service_new_blk">
                {/* <MaintenanceForm
                  properties={properties}
                  handleSavePerson={handleSavePerson}
                  isFormProcessing={isFormProcessing}
                  staff_row={staff_row}
                /> */}

                <h4>Adding Service Staff</h4>
                <div className="search_staff_form flex">
                  <div className="search_field">
                    {/* <input
                      type="text"
                      placeholder="Search City /state"
                      className="txtBox"
                      defaultValue={searchCityState}
                      onChange={handleSearchCityState}
                    /> */}
                    <div className="flex">
                      <div className="col_select invite_wrap">
                        <label>State</label>
                        <Select
                          className="input"
                          options={states}
                          onChange={(val) => {
                            handleSearchCityState(val, "state_id");
                          }}
                          defaultValue={{label:"State",value:""}}
                          label="Select State"
                        />
                      </div>
                      <div className="col_select">
                        {/* <Select
                          className="input"
                          options={stateCities}
                          onChange={(val) => {
                            handleSearchCityState(val, "city_id");
                          }}
                          label="Select City"
                        /> */}
                        <label>City</label>
                        <input type="text" className="txtBox" id="city_id" onChange={(e) => {
                            handleSearchCityState(e.target, "city_id");
                          }} placeholder="City" defaultValue={formData?.city_id} />
                      </div>
                      <div className="col_select invite_wrap">
                      <label>Service Filter</label>
                        <Select
                          className="input"
                          options={categories}
                          onChange={(val) => {
                            handleSearchCityState(val, "category_id");
                          }}
                          defaultValue={categories[0]}
                          label="Select Category"
                        />
                      </div>
                    </div>
                    {/* <button>
                      <img src="/images/MagnifyingGlass.svg" />
                    </button> */}
                  </div>
                  <div className="bTn">
                  <div className="gap_line_divide"></div>
                    <button
                      type="button"
                      className="webBtn colorBtn"
                      onClick={openInvitePopup}
                    >
                      Direct Invite
                    </button>
                  </div>
                </div>
                <div className="result_maintenance_flex">
                  <div className="flex relative">
                    {cityStateLoading ? (
                      <div className="staff_loading">
                        <div class="loadingio-spinner">
                          <div class="ldio-inner">
                            <div></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {cityStateUsers?.length > 0 ? (
                      cityStateUsers?.map((cityStateUser, index) => {
                        return (
                          <div className="card_col">
                            <div className="inner_card">
                              <div
                                className="popup_maintenance_new"
                                onClick={() => openDetailPopup(cityStateUser)}
                              ></div>
                              <div className="info_user">
                                <div className="icon_user">
                                  <img
                                    src={cityStateUser?.image}
                                    alt={
                                      cityStateUser?.fname +
                                      " " +
                                      cityStateUser?.lname
                                    }
                                  />
                                </div>
                                <span>
                                  {cityStateUser?.fname +
                                    " " +
                                    cityStateUser?.lname}
                                </span>
                              </div>
                              <div className="detail_user">
                                <div className="rating_rate">
                                  <div className="review_star">
                                    <i className="fa fa-star"></i>
                                    <span>
                                      {" "}
                                      {cityStateUser?.total_ratings} ({" "}
                                      {cityStateUser?.reviews?.length} Reviews)
                                    </span>
                                  </div>
                                  <div className="rate_rate">
                                    {helpers.format_amount(
                                      cityStateUser?.hourly_rate
                                    )}
                                    /hr
                                  </div>
                                </div>
                                {cityStateUser?.trade_categories_arr?.length >
                                0 ? (
                                  <div className="services_count">
                                    {cityStateUser?.trade_categories_arr
                                      ?.slice(0, 5)
                                      ?.map((trade_category, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="service_icon"
                                            data-hover={trade_category?.toUpperCase()}
                                          >
                                            <img
                                              src={
                                                trade_category?.toLowerCase() ===
                                                "pest-control"
                                                  ? "/images/" +
                                                    trade_category?.toLowerCase() +
                                                    ".png"
                                                  : "/images/" +
                                                    trade_category?.toLowerCase() +
                                                    ".svg"
                                              }
                                              alt={trade_category}
                                            />
                                            {/* <span>{trade_category}</span> */}
                                          </div>
                                        );
                                      })}
                                    {cityStateUser?.trade_categories_arr
                                      ?.length > 5 ? (
                                      <span
                                        className="more_service"
                                        onMouseEnter={() =>
                                          setIsMoreHovered(true)
                                        }
                                        onMouseLeave={() =>
                                          setIsMoreHovered(false)
                                        }
                                      >
                                        +
                                        {cityStateUser?.trade_categories_arr
                                          ?.length - 5}
                                        <div className="after_hover_blk">
                                          {cityStateUser?.trade_categories_arr?.map(
                                            (trade_category, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className="service_icon_hover"
                                                  data-hover={trade_category?.toUpperCase()}
                                                >
                                                  <img
                                                    src={
                                                      trade_category?.toLowerCase() ===
                                                      "pest-control"
                                                        ? "/images/" +
                                                          trade_category?.toLowerCase() +
                                                          ".png"
                                                        : "/images/" +
                                                          trade_category?.toLowerCase() +
                                                          ".svg"
                                                    }
                                                    alt={trade_category}
                                                  />
                                                  <span>{trade_category}</span>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {cityStateUser?.staff_pending_requests > 0 ? (
                                  <div className="badge yellow_badge">
                                    Pending
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="action_icons">
                                {/* <Link
                                  href=""
                                  className="colorBtn webBtn mini_web_btn"
                                  type="button"
                                >
                                  <img src="/images/chat-icon.svg" alt="" />
                                </Link> */}
                                <StartChat
                                  tenant_id={cityStateUser?.mem_id}
                                  is_staff_page={true}
                                />
                                {parseInt(cityStateUser?.is_staff_member) !==
                                1 ? (
                                  <button
                                    onClick={() => openPopup(cityStateUser)}
                                    className="webBtn mini_web_btn"
                                    type="button"
                                  >
                                    <i className="fa fa-plus"></i>
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                              {cityStateUser?.properties?.length > 0 ? (
                                <div
                                  className={
                                    isMoreHovered
                                      ? "property_hover_show more_no"
                                      : "property_hover_show"
                                  }
                                >
                                  <ul>
                                    {cityStateUser?.properties?.map(
                                      (property, index) => {
                                        return (
                                          <li key={index}>{property?.title}</li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="alert alert-danger">
                        No staff users found!
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {popup?.item !== null && popup?.item?.id > 0 ? (
        <DirectInvitePopup
          closePopup={closePopup}
          properties={properties}
          item={popup?.item}
        />
      ) : (
        ""
      )}

      {invitePopup ? (
        <InvitePopup
          closeInvitePopup={closeInvitePopup}
          properties={direct_properties}
        />
      ) : (
        ""
      )}

      {detailPopup !== null ? (
        <DetailPopup
          closeDetailPopup={closeDetailPopup}
          item={detailPopup}
          is_add_staff_page={true}
        />
      ) : (
        ""
      )}
    </>
  );
}

import React, { useEffect } from "react";
import Breadcrumb from "./sections/breadcrumb";

import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import { fetchSingleMaintenanceRequest } from "../../../states/actions/fetchMaintenanceRequests";
import { consoleLog, getServerImage } from "../../../helpers/api";
import { NavLink, useParams } from "react-router-dom";
import PropertyBlk from "../../common/propertyBlk";
import { useState } from "react";

import AssignedStaffMembers from "./maintenance-requests/assigned-staff-members";
import { useNavigate } from "react-router-dom";
import http from "../../../helpers/http";
import * as helpers from "../../../helpers/api";
import Text from "../../common/Text";
import toast from "react-hot-toast";
import StartChat from "./maintenance-requests/startChat";
import MaintenancePayForm from "./maintenance-requests/payForm";
import PayLandlordForm from "./maintenance-requests/pay-landlord-form";
import CompleteLandlordPopup from "./maintenance-requests/complete-landlord-popup";
import Error from "../Error";
export default function ViewMaintenanceRequest({ mem_type }) {
  const navigate = useNavigate();
  const { request_id, type } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchMaintenanceRequests.content);
  const [reqStatus, setReqStatus] = useState(null);
  const isLoading = useSelector(
    (state) => state.fetchMaintenanceRequests.isLoading
  );
  const [isCommericlalStaffAssigned, setCommercialStaffAssigned] =
    useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [payPopup, setPayPopup] = useState(null);
  const [payLandlord, setPayLandlord] = useState(null);
  const [completedLandlordPopup, setCompletedLandlordPopup] = useState(null);
  useEffect(() => {
    dispatch(
      fetchSingleMaintenanceRequest(
        request_id,
        type ? (type === "landlord" ? "received" : type) : "no_type",
        "no_req_type"
      )
    );
  }, []);
  useEffect(() => {
    if (data?.maintenance_row?.id > 0) {
      setReqStatus(data?.maintenance_row?.status);
      if (data?.maintenance_row?.is_commercial_staff) {
        setCommercialStaffAssigned(true);
      }
    }
  }, [data?.maintenance_row]);

  useDocumentTitle(
    request_id != undefined && request_id != null && request_id !== ""
      ? "View Service Request - Loftus"
      : "Loftus"
  );

  const handleDelete = (e) => {
    e.preventDefault();
    setIsDeleted(true);
    http
      .post(
        "delete-maintenance-request/" + request_id,
        helpers.doObjToFormData({
          request_id: request_id,
          request_by:
            maintenance_row?.is_landlord === 1 &&
            maintenance_row?.is_landlord_staff === 1
              ? "landlord"
              : "tenant",
        })
      )
      .then(({ data }) => {
        setIsDeleted(false);
        if (data.status) {
          toast.success(data?.msg);
          setTimeout(() => {
            navigate(`/service-requests`, {
              state: { refresh: true },
            });
          }, 2000);
        } else {
          toast.error(<Text string={data.msg} parse={true} />);
        }
      })
      .catch((error) => {
        setIsDeleted(false);
        toast.error(error);
        return;
      });
  };
  if (isLoading) return <LoadingScreen />;
  const maintenance_row = data?.maintenance_row;
  const staffMembers = maintenance_row?.staffMembers;
  const assigned_staff_members = maintenance_row?.assigned_staff_members;
  const bank_accounts = data?.bank_accounts;
  console.log(
    "isCommericlalStaffAssigned",
    isCommericlalStaffAssigned,
    maintenance_row
  );

  return (
    <>
      {maintenance_row?.status === 0 ? (
        <h1>Not found</h1>
      ) : (
        <main index className="main_dashboard">
          <section className="dashboard">
            <div className="contain">
              <Breadcrumb
                page_name={"Service Request: #" + maintenance_row?.invoice_id}
                parent_name="Service Request"
                parent_link="/service-requests"
              />
              <div className="page_body">
                <div className="offer_dash_detail_page">
                  <div className="flex">
                    <div className="colL">
                      <div className="sender_bio flex">
                        <div className="sender_offer_col wide_maintenance_col_purpose">
                          <h4 className="ofer_heading">Tenant</h4>
                          <div className="inner offer_blk_border">
                            <div className="mini_icon">
                              <img
                                src={getServerImage(
                                  "storage/members/",
                                  maintenance_row?.tenant_image
                                )}
                                alt={maintenance_row?.tenant_name}
                              />
                            </div>
                            <div className="bio_info">
                              <div className="name">
                                <h4>{maintenance_row?.tenant_name}</h4>
                                {parseInt(
                                  maintenance_row?.tenant_logged_user
                                ) !== 1 ? (
                                  <StartChat
                                    tenant_id={maintenance_row?.tenant_id}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="gap_line_divide"></div>
                      <div className="property_card propert_list">
                        <PropertyBlk data={maintenance_row?.property_row} />
                        {parseInt(maintenance_row?.grant_permissions) === 1 ? (
                          <div className="permission_wrap">
                            <div className="blk permission_blk">
                              <span>Permission to enter?</span>
                              <div className="icon">
                                <img src="/images/granted-check.png" />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="permission_wrap">
                            <div className="blk permission_blk">
                              <span>Permission to enter?</span>
                              <div className="icon">
                                <img src="/images/permissions-cross.png" />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="colR relative">
                      <h4 className="ofer_heading">Purpose</h4>
                      <div className="offer_cstm_ul">
                        <p>{maintenance_row?.purpose}</p>
                      </div>

                      <div className="gap_line_divide"></div>
                      <h4 className="ofer_heading">Description</h4>
                      <div className="offer_cstm_ul">
                        <p>{maintenance_row?.description}</p>
                      </div>
                      <div className="gap_line_divide"></div>
                      <AssignedStaffMembers
                        staffMembers={staffMembers}
                        request_id={request_id}
                        type={type}
                        assigned_staff_members={assigned_staff_members}
                        reqStatus={reqStatus}
                        setReqStatus={setReqStatus}
                        is_landlord={parseInt(maintenance_row?.is_landlord)}
                        is_landlord_staff={parseInt(
                          maintenance_row?.is_landlord_staff
                        )}
                        mem_type={mem_type}
                        setCommercialStaffAssigned={setCommercialStaffAssigned}
                        isCommericlalStaffAssigned={isCommericlalStaffAssigned}
                      />
                      <div className="gap_line_divide"></div>
                      {parseInt(maintenance_row?.is_landlord) &&
                      reqStatus !== "completed" &&
                      reqStatus !== "finished" ? (
                        <>
                          <div className="bTn offer_cstm_ul offer_action_btn">
                            <>
                              {maintenance_row?.type === "landlord" ? (
                                <NavLink
                                  to={
                                    "/edit-service-request/" +
                                    maintenance_row?.type +
                                    "/" +
                                    request_id
                                  }
                                  className="webBtn colorBtn "
                                >
                                  Edit
                                </NavLink>
                              ) : (
                                ""
                              )}
                              <NavLink
                                to="#!"
                                className="webBtn"
                                onClick={handleDelete}
                                disabled={setIsDeleted}
                              >
                                Delete{" "}
                                {isDeleted ? <i className="spinner"></i> : ""}
                              </NavLink>
                            </>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {parseInt(maintenance_row?.is_landlord) ? (
                        parseInt(maintenance_row?.is_landlord_staff) === 1 &&
                        reqStatus !== "completed" ? (
                          <>
                            <div className="gap_line_divide"></div>
                            <div className="bTn offer_cstm_ul offer_action_btn">
                              <a
                                href="#!"
                                className="webBtn colorBtn green"
                                onClick={() =>
                                  setCompletedLandlordPopup(maintenance_row)
                                }
                              >
                                Mark as Complete
                              </a>
                            </div>
                          </>
                        ) : (
                          ""
                        )
                      ) : reqStatus !== "completed" &&
                        reqStatus !== "finished" &&
                        parseInt(maintenance_row?.is_tenant) === 1 ? (
                        <div className="bTn offer_cstm_ul offer_action_btn">
                          <>
                            <NavLink
                              to={
                                "/edit-service-request/" +
                                maintenance_row?.type +
                                "/" +
                                request_id
                              }
                              className="webBtn colorBtn"
                            >
                              Edit
                            </NavLink>
                            <NavLink
                              to="#!"
                              className="webBtn"
                              onClick={handleDelete}
                              disabled={setIsDeleted}
                            >
                              Delete{" "}
                              {isDeleted ? <i className="spinner"></i> : ""}
                            </NavLink>
                          </>
                        </div>
                      ) : (
                        ""
                      )}
                      {reqStatus === "assigned" &&
                      parseInt(maintenance_row?.is_staff_profile) === 1 &&
                      parseInt(maintenance_row?.is_landlord_staff) !== 1 ? (
                        <>
                          <div className="bTn offer_cstm_ul offer_action_btn">
                            <a
                              href="#!"
                              className="webBtn colorBtn green"
                              onClick={() => setPayPopup(maintenance_row)}
                            >
                              Mark as Complete
                            </a>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {parseInt(maintenance_row?.is_tenant) === 1 &&
                      reqStatus !== "completed" &&
                      parseInt(maintenance_row?.is_landlord_staff) === 1 ? (
                        <>
                          <div className="gap_line_divide"></div>
                          <div className="bTn offer_cstm_ul offer_action_btn">
                            <a
                              href="#!"
                              className="webBtn colorBtn green"
                              onClick={() =>
                                setCompletedLandlordPopup(maintenance_row)
                              }
                            >
                              Mark as Complete
                            </a>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {reqStatus !== "assigned" &&
                      reqStatus !== "pending" &&
                      maintenance_row?.hours_worked !== null &&
                      parseInt(maintenance_row?.is_tenant) !== 1 &&
                      parseInt(maintenance_row?.is_landlord_staff) !== 1 ? (
                        <div className="new_wide_blk">
                          <div className="gap_line_divide"></div>
                          <div className="flex mainPayFlex">
                            <h4 className="ofer_heading">Payment</h4>
                            {parseInt(maintenance_row?.is_landlord) &&
                            reqStatus !== "completed" &&
                            parseFloat(maintenance_row?.total_cost) > 0 ? (
                              <a
                                href="#"
                                className="webBtn"
                                onClick={() => setPayLandlord(maintenance_row)}
                              >
                                Pay Now
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                          {parseInt(maintenance_row?.is_tenant) !== 1 ? (
                            <div className="cost_materials_flex">
                              <ul class="new_listing_list">
                                <li>
                                  <span>
                                    {maintenance_row?.hours_worked} hrs @{" "}
                                    {helpers.format_amount(
                                      parseFloat(maintenance_row?.offering_rate)
                                    )}
                                    /hr
                                  </span>
                                  <em>
                                    {helpers.format_amount(
                                      parseFloat(
                                        maintenance_row?.offering_rate
                                      ) *
                                        parseInt(maintenance_row?.hours_worked)
                                    )}
                                  </em>
                                </li>
                                {maintenance_row?.request_additional_costs?.map(
                                  (request_additional_cost, index) => {
                                    return (
                                      <li>
                                        <span>
                                          {request_additional_cost?.name}
                                        </span>
                                        <em>
                                          {helpers.format_amount(
                                            request_additional_cost?.cost
                                          )}
                                        </em>
                                      </li>
                                    );
                                  }
                                )}
                                {parseInt(maintenance_row?.is_landlord) &&
                                parseFloat(maintenance_row?.total_cost) > 0 ? (
                                  <>
                                    {/* <li>
                                      <span>Processing Fee</span>
                                      <em>
                                        {helpers.format_amount(
                                          maintenance_row?.processing_fee
                                        )}
                                      </em>
                                    </li> */}
                                    <li>
                                      <span>Total: </span>
                                      <strong>
                                        {helpers.format_amount(
                                          parseFloat(
                                            maintenance_row?.total_cost
                                          )
                                        )}
                                      </strong>
                                    </li>
                                  </>
                                ) : parseInt(
                                    maintenance_row?.is_staff_profile
                                  ) === 1 &&
                                  parseFloat(maintenance_row?.total_cost) >
                                    0 ? (
                                  <>
                                    <li>
                                      <span>Processing Fee</span>
                                      <em>
                                        {helpers.format_amount(
                                          maintenance_row?.processing_fee
                                        )}
                                      </em>
                                    </li>
                                    <li>
                                      <span>Total: </span>
                                      <strong>
                                        {helpers.format_amount(
                                          parseFloat(
                                            maintenance_row?.total_cost
                                          ) -
                                            parseFloat(
                                              maintenance_row?.processing_fee
                                            )
                                        )}
                                      </strong>
                                    </li>
                                  </>
                                ) : (
                                  <li>
                                    <span>Total: </span>
                                    <strong>
                                      {helpers.format_amount(
                                        maintenance_row?.total_cost
                                      )}
                                    </strong>
                                  </li>
                                )}
                              </ul>
                              {/* <hr />
                          <h5>
                            Total :{" "}
                            {helpers.format_amount(maintenance_row?.total_cost)}
                          </h5> */}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {(reqStatus === "assigned" || reqStatus === "pending") &&
                      parseInt(maintenance_row?.is_commercial_staff) === 1 ? (
                        <>
                          <div className="gap_line_divide"></div>
                          <div className="bTn offer_cstm_ul offer_action_btn">
                            <a
                              href="#!"
                              className="webBtn colorBtn green"
                              onClick={() =>
                                setCompletedLandlordPopup(maintenance_row)
                              }
                            >
                              Mark as Complete
                            </a>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {(reqStatus === "assigned" || reqStatus === "pending") &&
                      parseInt(maintenance_row?.is_commercial_landlord) ===
                        1 ? (
                        <>
                          <div className="gap_line_divide"></div>
                          <div className="bTn offer_cstm_ul offer_action_btn">
                            <a
                              href="#!"
                              className="webBtn colorBtn green"
                              onClick={() =>
                                setCompletedLandlordPopup(maintenance_row)
                              }
                            >
                              Mark as Complete
                            </a>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {reqStatus !== "assigned" &&
                      reqStatus !== "pending" &&
                      maintenance_row?.hours_worked !== null &&
                      parseInt(maintenance_row?.is_tenant) !== 1 &&
                      parseInt(maintenance_row?.is_landlord_staff) !== 1 &&
                      parseInt(maintenance_row?.is_landlord) &&
                      reqStatus !== "completed" &&
                      parseFloat(maintenance_row?.total_cost) <= 0 ? (
                        <>
                          <div className="gap_line_divide"></div>
                          <div className="bTn offer_cstm_ul offer_action_btn">
                            <a
                              href="#!"
                              className="webBtn colorBtn green"
                              onClick={() =>
                                setCompletedLandlordPopup(maintenance_row)
                              }
                            >
                              Mark as Complete
                            </a>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {reqStatus === "completed" ? (
                        <div className="new_wide_blk">
                          <div className="gap_line_divide"></div>
                          <div className="alert alert-success">
                            This request was completed on{" "}
                            {helpers.format_date(maintenance_row?.paid_date)}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {payPopup?.id > 0 ? (
            <MaintenancePayForm
              maintenance_row={maintenance_row}
              setPayPopup={setPayPopup}
              request_id={request_id}
              req_type={type}
            />
          ) : (
            ""
          )}
          {payLandlord?.id > 0 ? (
            <PayLandlordForm
              maintenance_row={payLandlord}
              setPayLandlord={setPayLandlord}
              bank_accounts={bank_accounts}
              is_landlord_staff={
                parseInt(maintenance_row?.is_landlord) &&
                parseInt(maintenance_row?.is_landlord_staff) === 1
                  ? true
                  : false
              }
            />
          ) : (
            ""
          )}
          {completedLandlordPopup?.id > 0 ? (
            <CompleteLandlordPopup
              maintenance_row={completedLandlordPopup}
              setCompletedLandlordPopup={setCompletedLandlordPopup}
              request_id={request_id}
              is_landlord_staff={
                parseInt(maintenance_row?.is_landlord) &&
                parseInt(maintenance_row?.is_landlord_staff) === 1
                  ? true
                  : false
              }
            />
          ) : (
            ""
          )}
        </main>
      )}
    </>
  );
}

import React from 'react'
import { ErrorMessage } from '@hookform/error-message';
import { format_amount } from '../../../../helpers/api';

export default function RentalListingStep5({ step, changeStep, register, errors, control, watchAllFields, isValid, trigger, siteSettings, calculateListingProcessingFee }) {
    return (
        <>
            <fieldset>
                <div className="form_row row justify_center_flex">
                    <div className="col-xs-6">
                        <h6>Expires In</h6>
                        <div className="form_blk deposit_form_blk">
                            <input type="text" name="expires_in" id="expires_in" className="txtBox"  {...register("expires_in", {

                                pattern: {
                                    value: /^[0-9]+$/,
                                    message: 'Invalid value',
                                },
                                required: "Required",
                                min: {
                                    value: 1,
                                    message: "Value must be greater than 0."
                                }


                            })} />
                            <span className='dollar_label'>mth</span>
                            <ErrorMessage
                                errors={errors}
                                name="expires_in"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                            />
                        </div>
                    </div>
                </div>
                <div className="form_row row justify_center_flex">
                    <div className="col-xs-6">
                        <ul className="total_count_price_listing full_wide_price_listing cell_full_wide_lst_cell">
                            <li>
                                <em>Listing Fee</em>
                                <em>{format_amount(parseFloat(siteSettings?.site_listing_fee))}/Monthly</em>
                            </li>
                            <li>
                                <em>Total Listing Fee</em>
                                <em>{watchAllFields?.expires_in !== undefined && watchAllFields?.expires_in !== '' ? format_amount(parseInt(watchAllFields?.expires_in) * parseFloat(siteSettings?.site_listing_fee)) : '$0'}</em>
                            </li>
                            <li>
                                <em>Processing Fee</em>
                                <em>{format_amount(parseFloat(calculateListingProcessingFee()))}</em>
                            </li>
                            <li>
                                <strong>Total Due</strong>
                                <strong>{watchAllFields?.expires_in !== undefined && watchAllFields?.expires_in !== '' ? format_amount((parseFloat(parseInt(watchAllFields?.expires_in) * parseFloat(siteSettings?.site_listing_fee)) + parseFloat(calculateListingProcessingFee(calculateListingProcessingFee))).toFixed(2)) : format_amount(parseFloat(0))}</strong>
                            </li>
                        </ul>
                    </div>

                </div>
                <div className="cmnBtn text-center">
                    <button type="button" className="webBtn backBtn" onClick={() => changeStep(step - 1)}>Previous</button>
                    {
                        !isValid ?
                            <button type="button" className="webBtn colorBtn nextBtn" onClick={() => {
                                trigger();
                            }} >Next</button>
                            :
                            <button type="button" className="webBtn colorBtn nextBtn" onClick={() => changeStep(step + 1)} disabled={!isValid}>Next</button>
                    }
                </div>
            </fieldset>
        </>
    )
}

import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { postData } from "../../../../helpers/api";
import toast from "react-hot-toast";
import {
  saveCard,
  cardSavedStatusChange,
} from "../../../../states/actions/payments";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
export default function AddNewCreditCard({
  setAddNewCreditCard,
  siteSettings,
}) {
  const dispatch = useDispatch();
  const isAddNewCardProcessing = useSelector(
    (state) => state.payments.isAddNewCardProcessing
  );
  const isAddNewCardProcessingListings = useSelector(
    (state) => state.fetchSingleUserListing.isAddNewCardProcessing
  );
  const isCardSaved = useSelector((state) => state.payments.isCardSaved);
  const isCardSavedListings = useSelector(
    (state) => state.fetchSingleUserListing.isCardSaved
  );
  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const stripe = useStripe();
  const elements = useElements();
  const [checkoutError, setCheckoutError] = useState();
  const [isProcessing, setProcessingTo] = useState(false);
  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };
  useEffect(() => {
    if (isCardSaved || isCardSavedListings) {
      setAddNewCreditCard(false);
      dispatch(cardSavedStatusChange());
    }
  }, [isCardSaved]);
  const states = siteSettings.states;
  const countries = siteSettings.countries;
  const watchAllFields = watch();
  const onSubmit = async (frmData) => {
    setProcessingTo(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement(CardElement);
    try {
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        // billing_details: billingDetails
      });
      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      } else {
        var form_data = new FormData();
        for (var key in frmData) {
          form_data.append(key, watchAllFields[key]);
        }
        form_data.append("payment_method", paymentMethodReq.paymentMethod.id);
        form_data.append("card_holder_name", frmData?.card_holder_name);
        await postData("api/create-lease-payment-intent", form_data).then(
          (data) => {
            let client_secret_setup = data.arr.setup_client_secret;
            if (data.status === 1) {
              let card_result = stripe.confirmCardSetup(client_secret_setup, {
                payment_method: {
                  card: cardElement,
                  // billing_details: billingDetails,
                },
              });

              if (card_result.error) {
                setCheckoutError(card_result.error.message);
                setProcessingTo(false);
                return;
              } else {
                let paymentMethod = "";
                let setup_intent_id = "";
                card_result.then((response) => {
                  if (response.error) {
                    setCheckoutError(response.error.message);
                    setProcessingTo(false);
                    return;
                  } else {
                    paymentMethod = response.setupIntent.payment_method;
                    setup_intent_id = response.setupIntent.id;
                    chargePayment(
                      paymentMethodReq,
                      setup_intent_id,
                      paymentMethod,
                      data.arr.customer
                    );
                  }
                });
              }
            } else {
              toast.error(data?.msg);
            }
          }
        );
      }
    } catch (err) {
      setCheckoutError(err.message);
    }
  };
  const chargePayment = async (
    paymentMethodReq,
    setup_id,
    paymentMethodSetup,
    customer_id
  ) => {
    let data = watchAllFields;

    let newData = {
      ...data,
      payment_method_id: paymentMethodReq.paymentMethod.id,
      customer_id: customer_id,
      card_number: paymentMethodReq.paymentMethod.card.last4,
      card_brand: paymentMethodReq.paymentMethod.card.brand,
      card_exp_month: paymentMethodReq.paymentMethod.card.exp_month,
      card_exp_year: paymentMethodReq.paymentMethod.card.exp_year,
      setup_id: setup_id,
      payment_method: "credit-card",
    };

    dispatch(saveCard(newData));
    // setAddNewCreditCard(false)
    setProcessingTo(false);
  };
  return (
    <section
      className="popup large_popup card_popup"
      data-popup="create-new-listing"
    >
      <div className="tableDv">
        <div className="tableCell">
          <div className="_inner">
            <ToastContainer />
            <div
              className="crosBtn"
              onClick={() => setAddNewCreditCard(false)}
            ></div>
            <h4>Add New Credit Card:</h4>
            <div className="row formRow">
              <div className="col-md-12">
                <form method="post" onSubmit={handleSubmit(onSubmit)}>
                  <div class="flex flex-2 cardSec">
                    <div class="inner credit_card_inner">
                      <CardElement
                        options={{
                          style: {
                            base: {
                              fontSize: "14px",
                              color: "#424770",
                              "::placeholder": {
                                color: "#aab7c4",
                              },
                            },
                            invalid: {
                              color: "#9e2146",
                            },
                          },
                        }}
                        onChange={handleCardDetailsChange}
                      />
                      {checkoutError && (
                        <div className="alert alert-danger">
                          {checkoutError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6">
                      <h6>First Name</h6>
                      <div className="form_blk">
                        <input
                          type="text"
                          name="fname"
                          id="fname"
                          className="txtBox"
                          defaultValue={watchAllFields?.fname}
                          {...register("fname", {
                            pattern: {
                              value: /^[a-zA-Z][a-zA-Z ]*$/,
                              message: "Invalid value",
                            },
                            required: "Required",
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="fname"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-xs-6">
                      <h6>Last Name</h6>
                      <div className="form_blk">
                        <input
                          type="text"
                          name="lname"
                          id="lname"
                          className="txtBox"
                          defaultValue={watchAllFields?.lname}
                          {...register("lname", {
                            pattern: {
                              value: /^[a-zA-Z][a-zA-Z ]*$/,
                              message: "Invalid value",
                            },
                            required: "Required",
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="lname"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-6">
                      <h6>Address Line 1</h6>
                      <div className="form_blk">
                        <input
                          type="text"
                          name="address1"
                          id="address1"
                          className="txtBox"
                          defaultValue={watchAllFields?.address1}
                          {...register("address1", {
                            required: "Required",
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="address1"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-xs-6">
                      <h6>Address Line 2</h6>
                      <div className="form_blk">
                        <input
                          type="text"
                          name="address2"
                          id="address2"
                          className="txtBox"
                          defaultValue={watchAllFields?.address2}
                          {...register("address2")}
                        />
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-6">
                      <h6>City</h6>
                      <div className="form_blk">
                        <input
                          type="text"
                          name="city"
                          id="city"
                          className="txtBox"
                          defaultValue={watchAllFields?.city}
                          {...register("city", { required: "Required" })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="city"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-xs-6">
                      <h6>ZIP Code</h6>
                      <div className="form_blk">
                        <input
                          type="text"
                          name="zip_code"
                          id="zip_code"
                          className="txtBox"
                          defaultValue={watchAllFields?.zip_code}
                          {...register("zip_code", {
                            required: "Required",
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Invalid value",
                            },
                            minLength: {
                              value: 5,
                              message: "Zip code should be  equal to 5 digits",
                            },
                            maxLength: {
                              value: 5,
                              message: "Zip code should be  equal to 5 digits",
                            },
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="zip_code"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-6">
                      <h6>Country</h6>
                      <div className="form_blk">
                        <Controller
                          name="country"
                          control={control}
                          {...register("country", { required: "Required" })}
                          defaultValue={watchAllFields?.country}
                          render={({ field }) => (
                            <Select
                              options={countries}
                              {...field}
                              label="Select Country"
                              value={countries?.find(
                                (option) => option.value === field?.value
                              )}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="country"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-xs-6">
                      <h6>State</h6>
                      <div className="form_blk">
                        <Controller
                          name="state"
                          control={control}
                          defaultValue={watchAllFields?.state}
                          {...register("state", { required: "Required" })}
                          render={({ field }) => (
                            <Select
                              options={states}
                              {...field}
                              label="Select State"
                              value={states?.find(
                                (option) => option.value === field?.value
                              )}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="state"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div class="bTn  text-center">
                    <button
                      type="submit"
                      class="webBtn colorBtn"
                      disabled={
                        isProcessing ||
                        isAddNewCardProcessing ||
                        isAddNewCardProcessingListings
                          ? "disabled"
                          : ""
                      }
                    >
                      Save{" "}
                      {isProcessing ||
                      isAddNewCardProcessing ||
                      isAddNewCardProcessingListings ? (
                        <i className="spinner"></i>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";
import { FaInstagram, FaTiktok } from "react-icons/fa";

const ShareButtons = ({ property_url, sharePopup }) => {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check out this article!",
          text: "I thought you’d like this article",
          url: window.location.href,
        });
      } catch (error) {
        console.error("Sharing failed:", error);
      }
    } else {
      alert("Sharing not supported on this browser");
    }
  };
  return (
    <ul className="icons" style={{ display: "flex", gap: "10px" }}>
      {/* Facebook Share */}
      <FacebookShareButton url={property_url} quote={sharePopup?.popup_title}>
        <i className="fa fa-facebook-f">
          {/* <FacebookIcon size={32} round /> */}
        </i>
      </FacebookShareButton>

      {/* X (Twitter) Share */}
      <TwitterShareButton
        url={property_url}
        title={sharePopup?.popup_title}
        via={sharePopup?.description}
      >
        <i className="fa fa-twitter">{/* <TwitterIcon size={32} round /> */}</i>
      </TwitterShareButton>

      {/* Instagram Link (as Instagram does not support direct URL sharing) */}
      <button type="button" onClick={handleShare}>
        <i className="fa fa-instagram">
          {/* <FaInstagram size={32} style={{ color: "#E1306C" }} /> */}
        </i>
      </button>

      {/* TikTok Link (as TikTok does not support direct URL sharing) */}
      <button type="button" onClick={handleShare}>
        {/* <i className="fa fa-tiktok"> */}
        <FaTiktok size={20} style={{ color: "#000000" }} />
        {/* </i> */}
      </button>

      {/* Additional Share Buttons */}
      <EmailShareButton
        url={property_url}
        subject={sharePopup?.popup_title}
        body={sharePopup?.description}
      >
        <i className="fa fa-envelope">{/* <EmailIcon size={32} round /> */}</i>
      </EmailShareButton>

      <WhatsappShareButton
        url={property_url}
        title={sharePopup?.popup_title}
        separator=" "
      >
        <i className="fa fa-whatsapp">
          {/* <WhatsappIcon size={32} round /> */}
        </i>
      </WhatsappShareButton>

      <LinkedinShareButton
        url={property_url}
        title={sharePopup?.popup_title}
        summary={sharePopup?.description}
        source="Loftus"
      >
        <i className="fa fa-linkedin">
          {/* <LinkedinIcon size={32} round /> */}
        </i>
      </LinkedinShareButton>
    </ul>
  );
};

export default ShareButtons;

import React, { useEffect, useState } from "react";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import LeaseAdditionalCosts from "./lease-details/lease-costs";
import { NavLink, useParams } from "react-router-dom";

import {
  fetchLeaseDetails,
  cancelTerminateLease,
} from "../../../states/actions/leases";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import Error from "../Error";
import LeaseDetailsSection from "./create-lease-sections/lease-details";
import LeaseTenantsSection from "./create-lease-sections/lease-tenants-section";
import { format_date, getServerImage } from "../../../helpers/api";
import ActiveProperty from "../dashboard/sections/activeProperty";
import LeaseDetailsTerminateLeasePopup from "./lease-details/terminate-lease-popup";
import LeaseDetailsWithdrawLeasePopup from "./lease-details/withdraw-lease-popup";
import { ToastContainer } from "react-toastify";

export default function LeaseDetails({ mem_type }) {
  const mem_permissions = localStorage.getItem("mem_permissions");

  const [leaseTerminate, setLeaseTerminate] = useState({
    show: false,
    item: null,
  });
  const [leaseWithdraw, setLeaseWithdraw] = useState({
    show: false,
    item: null,
  });
  const [leaseTerminateCancel, setLeaseTerminateCancel] = useState({
    show: false,
    item: null,
  });

  const { lease_id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.leases.lease_details_content);
  const { lease_row, member } = data;
  const isLoading = useSelector((state) => state.leases.isDetailsLoading);
  const isTerminateCancelFormLoading = useSelector(
    (state) => state.leases.isTerminateCancelFormLoading
  );
  useEffect(() => {
    dispatch(fetchLeaseDetails(lease_id));
  }, []);
  const handleTermincationCancellation = (e) => {
    e.preventDefault();
    dispatch(cancelTerminateLease({ lease_id: lease_row?.id }));
  };

  useDocumentTitle("Lease# " + lease_id + " - Loftus");
  if (isLoading) return <LoadingScreen />;
  if (
    lease_row === undefined ||
    lease_row === null ||
    lease_row?.id <= 0 ||
    lease_row === ""
  ) {
    return <Error />;
  }
  console.log(lease_row?.allow_edit,'lease_row?.allow_edit')
  return (
    <>
      <main index className="main_dashboard">
        <section className="dashboard">
          <div className="contain">
            <Breadcrumb
              page_name={"Lease# " + lease_id}
              parent_name="Leases"
              parent_link="/leases"
              lease_row={lease_row}
            />
            <div className="page_body">
              <div className="offer_dash_detail_page">
                <div className="flex">
                  <div className="colL">
                    <div className="property_card propert_list grid_dash_property_listing dash_property_listing lease_property_details">
                      <ActiveProperty
                        item={lease_row?.property_row}
                        leases_page={true}
                      />
                    </div>
                    <div className="gap_line_divide"></div>
                    {lease_row?.attachment ? (
                      <div className="bTn justify_center_flex">
                        <a
                          href={getServerImage(
                            "storage/attachments/",
                            lease_row?.attachment
                          )}
                          className="webBtn colorBtn"
                          target="_blank"
                          download
                        >
                          <img
                            src="/images/document.png"
                            alt={getServerImage(
                              "storage/attachments/",
                              lease_row?.attachment
                            )}
                          />{" "}
                          Lease Document
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="colR">
                    <LeaseDetailsSection lease={lease_row} api_data={true} />
                    <div className="gap_line_divide"></div>

                    <LeaseTenantsSection
                      tenants={lease_row?.tenants}
                      lease_status={lease_row?.status}
                      lease={lease_row}
                    />

                    <LeaseAdditionalCosts
                      setLeaseTerminate={setLeaseTerminate}
                      setLeaseTerminateCancel={setLeaseTerminateCancel}
                      lease_row={lease_row}
                      type="landlord"
                      one_time_costs={lease_row?.one_time_costs}
                      recurring_costs={lease_row?.recurring_costs}
                      termination_costs={lease_row?.termination_costs}
                      mem_type={mem_type}
                      parking_costs={lease_row?.parking_costs}
                      promo_code={
                        lease_row?.promo_code === "yes" ? true : false
                      }
                    />

                    {lease_row?.status === "rejected" ? (
                      <div className="alert alert-danger">
                        Lease declined on {format_date(lease_row?.action_date)}.
                      </div>
                    ) : parseInt(lease_row?.allow_edit) === 1 ||
                      lease_row?.status === "pending" ? (
                      (mem_type === "company" && mem_permissions === "admin") ||
                      mem_type === "member" ? (
                        <>
                          <div className="bTn lease_action_btn landlord_lease_details">
                            {parseInt(lease_row?.allow_edit) === 1 &&
                            lease_row?.status === "pending" ? (
                              <NavLink
                                to={"/edit-lease/" + lease_row?.encoded_id}
                                className="webBtn colorBtn"
                              >
                                Edit
                              </NavLink>
                            ) : (
                              ""
                            )}
                            {lease_row?.status === "pending" ? (
                              <NavLink
                                to="#!"
                                className="webBtn"
                                onClick={() => setLeaseWithdraw({ show: true })}
                              >
                                Withdraw
                              </NavLink>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (mem_type === "company" &&
                        mem_permissions === "admin") ||
                      mem_type === "member" ? (
                      lease_row?.end_date_passed !== undefined &&
                      parseInt(lease_row?.end_date_passed) !== 1 &&
                      lease_row?.allow_terminate_button !== 1 &&
                      lease_row?.allow_terminate_button !== "1" ? (
                        <div className="bTn lease_action_btn landlord_lease_details">
                          {parseInt(lease_row?.allow_edit) === 1 &&
                          lease_row?.status === "pending" ? (
                            <NavLink
                              to={"/edit-lease/" + lease_row?.encoded_id}
                              className="webBtn colorBtn"
                            >
                              Edit
                            </NavLink>
                          ) : (
                            ""
                          )}

                          {lease_row?.allow_terminate_button === 1 ||
                          lease_row?.allow_terminate_button === "1" ? (
                            <NavLink
                              to="#!"
                              className="webBtn"
                              onClick={() => setLeaseTerminate({ show: true })}
                            >
                              Terminate
                            </NavLink>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : lease_row?.allow_terminate_button === 1 ||
                        lease_row?.allow_terminate_button === "1" ? (
                        <>
                          <div className="line_border"></div>
                          <div className="bTn lease_action_btn landlord_lease_details">
                            <NavLink
                              to="#!"
                              className="webBtn"
                              onClick={() => setLeaseTerminate({ show: true })}
                            >
                              Terminate
                            </NavLink>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {leaseTerminate?.show === true ? (
          <LeaseDetailsTerminateLeasePopup
            lease_row={lease_row}
            setLeaseTerminate={setLeaseTerminate}
            leaseTerminateItem={leaseTerminate?.item}
          />
        ) : (
          ""
        )}
        {leaseWithdraw?.show === true ? (
          <LeaseDetailsWithdrawLeasePopup
            lease_row={lease_row}
            setLeaseWithdraw={setLeaseWithdraw}
          />
        ) : (
          ""
        )}
        {leaseTerminateCancel?.show === true ? (
          <div index className="popup small_popup">
            <div className="tableDv">
              <div className="tableCell">
                <ToastContainer />
                <div className="_inner">
                  <div
                    className="crosBtn"
                    onClick={() =>
                      setLeaseTerminateCancel({ show: false, item: null })
                    }
                  ></div>
                  <h4>Please confirm your lease termination cancellation.</h4>
                  <div className="bTn text-center">
                    <NavLink
                      to="#!"
                      className="webBtn"
                      onClick={() =>
                        setLeaseTerminateCancel({ show: false, item: null })
                      }
                    >
                      Cancel
                    </NavLink>
                    <button
                      type="button"
                      className="webBtn colorBtn"
                      disabled={isTerminateCancelFormLoading ? "disabled" : ""}
                      onClick={handleTermincationCancellation}
                    >
                      Confirm{" "}
                      {isTerminateCancelFormLoading ? (
                        <i className="spinner"></i>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </main>
    </>
  );
}

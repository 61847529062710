import React from "react";
import { getServerImage } from "../../../../helpers/api";
import { postChatMsg } from "../../../../states/actions/sendChat";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_SETTINGS } from '../../../../utils/siteSettings';
export default function LeaseTenantsSection({ tenants, member, lease_status, lease }) {
    const dispatch = useDispatch();
    const isFormProcessing = useSelector((state) => state.sendChat.isFormProcessing);

    const handleStartChat = tenant_id => {
        if (tenant_id !== undefined && tenant_id !== null && tenant_id !== '' && tenant_id > 0) {
            let frmData = { msg: "", property_mem_id: tenant_id }
            dispatch(postChatMsg(frmData, true));
        }
        else {
            toast.error('Invalid User or user does not exit in our system!',
                TOAST_SETTINGS
            ); return;
        }

    }
    return <>
        <h4 className="ofer_heading">Tenants</h4>
        <div className="lease_tenants_creat_box">
            <ToastContainer />
            <div className="sender_bio flex">
                {
                    tenants?.map((tenant, index) => {
                        return (
                            <div className="sender_offer_col" key={index}>
                                <div className={member?.id === tenant?.mem_id ? "inner offer_blk_border relative active" : "inner offer_blk_border relative"}>
                                    <div className="mini_icon">
                                        <img src={tenant?.thumb !== undefined && tenant?.thumb !== null && tenant?.thumb !== '' ? tenant?.thumb : getServerImage("storage/members/", '')} alt={tenant?.name} />
                                    </div>
                                    <div className="bio_info">
                                        <div className="name">
                                            <h4>{tenant?.name}</h4>
                                            {
                                                parseInt(lease?.expired) === 1 ?
                                                    <span className="badge red">Expired</span>
                                                    :

                                                    tenant?.status === 'accepted' ?
                                                        <span className="badge green">Accepted</span>
                                                        :
                                                        tenant?.status === "rejected" ?
                                                            <span className="badge red">Declined</span>
                                                            :
                                                            lease_status === 'rejected' ?
                                                                <span className="badge yellow">Incomplete</span>
                                                                :
                                                                <span className="badge yellow">Pending</span>
                                            }
                                        </div>
                                    </div>
                                    {
                                        member?.id !== tenant?.mem_id ?

                                            <div class="chat_mini_icon"><a href="#!" onClick={() => handleStartChat(tenant?.mem_id)} disabled={isFormProcessing}><i class="fa fa-comments"></i></a></div>
                                            :
                                            ""
                                    }

                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        <div className="gap_line_divide"></div>
    </>;
}

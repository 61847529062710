import React, { useEffect, useState } from "react";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import LeaseAdditionalCosts from "./lease-details/lease-costs";
import { NavLink, useParams } from "react-router-dom";

import {
  fetchLeaseDetails,
  tenantLeaseAction,
} from "../../../states/actions/leases";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import Error from "../Error";
import LeaseDetailsSection from "./create-lease-sections/lease-details";
import LeaseTenantsSection from "./create-lease-sections/lease-tenants-section";
import PropertyBlk from "../../common/propertyBlk";
import { getServerImage } from "../../../helpers/api";
import ActiveProperty from "../dashboard/sections/activeProperty";
import { ToastContainer } from "react-toastify";
export default function MyLeaseDetails({ mem_type }) {
  const [leaseActionPopup, setLeaseAction] = useState({
    show: false,
    type: null,
  });

  const { lease_id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.leases.lease_details_content);
  const tenant_action_date = useSelector(
    (state) => state.leases.tenant_action_date
  );
  const isLeaseActionFormLoading = useSelector(
    (state) => state.leases.isLeaseActionFormLoading
  );
  const lease_status = useSelector((state) => state.leases.lease_status);
  const { lease_row, member } = data;
  const isLoading = useSelector((state) => state.leases.isDetailsLoading);
  useEffect(() => {
    dispatch(fetchLeaseDetails(lease_id, true));
  }, []);
  useDocumentTitle("Lease# " + lease_id + " - Loftus");
  if (isLoading) return <LoadingScreen />;
  if (
    lease_row === undefined ||
    lease_row === null ||
    lease_row?.id <= 0 ||
    lease_row === ""
  ) {
    return <Error />;
  }
  const leaseAction = (status) => {
    dispatch(
      tenantLeaseAction({
        lease_id: lease_id,
        status: status,
      })
    );
  };

  return (
    <>
      <main index className="main_dashboard">
        <section className="dashboard">
          <div className="contain">
            <Breadcrumb
              page_name={"Lease# " + lease_id}
              parent_name="Leases"
              parent_link="/leases"
            />
            <div className="page_body">
              <div className="offer_dash_detail_page">
                <div className="flex">
                  <div className="colL">
                    <div className="property_card propert_list grid_dash_property_listing dash_property_listing lease_property_details">
                      <ActiveProperty
                        item={lease_row?.property_row}
                        lease_tenant_page={true}
                        leases_page={true}
                      />
                    </div>
                    <div className="gap_line_divide"></div>
                    {lease_row?.attachment ? (
                      <div className="bTn justify_center_flex">
                        <a
                          href={getServerImage(
                            "storage/attachments/",
                            lease_row?.attachment
                          )}
                          className="webBtn colorBtn"
                          target="_blank"
                          download
                        >
                          <img src="/images/document.png" alt="" /> Lease
                          Document
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="colR">
                    <LeaseDetailsSection lease={lease_row} api_data={true} />
                    <div className="gap_line_divide"></div>

                    <LeaseTenantsSection
                      tenants={lease_row?.tenants}
                      member={member}
                      lease_status={lease_row?.status}
                      lease={lease_row}
                    />

                    <LeaseAdditionalCosts
                      lease_row={lease_row}
                      one_time_costs={lease_row?.one_time_costs}
                      recurring_costs={lease_row?.recurring_costs}
                      termination_costs={lease_row?.termination_costs}
                      parking_costs={lease_row?.parking_costs}
                      promo_code={
                        lease_row?.promo_code === "yes" ? true : false
                      }
                    />

                    <div className="gap_line_divide"></div>
                    {lease_status === null || lease_status === "pending" ? (
                      <div className="bTn offer_cstm_ul offer_action_btn relative">
                        {isLeaseActionFormLoading ? (
                          <div className="loadingDisabled">
                            <i className="spinner"></i>
                          </div>
                        ) : (
                          ""
                        )}
                        <a
                          href="javascript:void(0)"
                          className="webBtn"
                          onClick={() =>
                            setLeaseAction({ show: true, type: "accepted" })
                          }
                        >
                          Accept
                        </a>
                        <a
                          href="javascript:void(0)"
                          className="webBtn colorBtn popBtn"
                          onClick={() =>
                            setLeaseAction({ show: true, type: "rejected" })
                          }
                        >
                          Reject
                        </a>
                      </div>
                    ) : lease_status === "accepted" ? (
                      <div className="alert alert-success">
                        Lease accepted on {tenant_action_date}.
                      </div>
                    ) : lease_status === "rejected" ? (
                      <div className="alert alert-danger">
                        Lease declined on {tenant_action_date}.
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {leaseActionPopup?.show === true ? (
          <div index className="popup small_popup">
            <ToastContainer />
            <div className="tableDv">
              <div className="tableCell">
                <div className="_inner">
                  <div
                    className="crosBtn"
                    onClick={() => setLeaseAction({ show: false, type: null })}
                  ></div>
                  <h4>
                    Are you sure you want to{" "}
                    {leaseActionPopup?.type === "accepted"
                      ? "accept"
                      : "decline"}{" "}
                    this lease?
                  </h4>
                  <div className="bTn text-center">
                    {
                      <NavLink
                        to="#!"
                        className="webBtn"
                        onClick={() => leaseAction(leaseActionPopup?.type)}
                        disabled={isLeaseActionFormLoading}
                      >
                        Yes{" "}
                        {isLeaseActionFormLoading ? (
                          <i className="spinner"></i>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    }
                    <NavLink
                      to="#!"
                      className="webBtn"
                      onClick={() =>
                        setLeaseAction({ show: false, type: null })
                      }
                    >
                      No
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </main>
    </>
  );
}

import React, { useEffect, useState } from "react";

export default function LeaseTabsMenu({
  count_landlord_leases,
  count_tenant_leases,
  pending_landlord_leases,
  type,
  setMyLeaseType,
  myLeaseType,
  tenant_pending_leases,
  tenant_accepted_leases,
  accepted_landlord_leases,
  rejected_landlord_leases,
  tenant_rejected_leases,
  handleType,
  setPropertyFilter,
  propertyFilter,
  handleFilterLease,
  properties,
  tenant_properties,
  leaseStatus,
  setLeaseStatus,
  expired_landlord_leases,
  tenant_expired_leases,
  tenant_properties_arr,
  leases_filter = "member",
  branches = [],
}) {
  const [tenantPropertiesArr, setTenantPropertiesArr] = useState([]);
  useEffect(() => {
    if (tenant_properties_arr?.length > 0) {
      setTenantPropertiesArr(tenant_properties_arr);
    } else {
      setTenantPropertiesArr(tenant_properties);
    }
  }, [tenant_properties_arr, tenant_properties]);

  return (
    <>
      <div className="flterFlexListing flex">
        <ul className="nav nav-tabs">
          {pending_landlord_leases?.length > 0 && type === "landlord" ? (
            <li className={myLeaseType === "pending" ? "active" : ""}>
              <a href="#!" onClick={() => setMyLeaseType("pending")}>
                Pending <span>({pending_landlord_leases?.length})</span>
              </a>
            </li>
          ) : (
            ""
          )}
          {accepted_landlord_leases?.length > 0 && type === "landlord" ? (
            <li className={myLeaseType === "accepted" ? "active" : ""}>
              <a href="#!" onClick={() => setMyLeaseType("accepted")}>
                Active <span>({accepted_landlord_leases?.length})</span>
              </a>
            </li>
          ) : (
            ""
          )}
          {rejected_landlord_leases?.length > 0 && type === "landlord" ? (
            <li className={myLeaseType === "rejected" ? "active" : ""}>
              <a href="#!" onClick={() => setMyLeaseType("rejected")}>
                Declined <span>({rejected_landlord_leases?.length})</span>
              </a>
            </li>
          ) : (
            ""
          )}
          {expired_landlord_leases?.length > 0 && type === "landlord" ? (
            <li className={myLeaseType === "expired" ? "active" : ""}>
              <a href="#!" onClick={() => setMyLeaseType("expired")}>
                Expired <span>({expired_landlord_leases?.length})</span>
              </a>
            </li>
          ) : (
            ""
          )}
          {tenant_pending_leases?.length > 0 && type === "tenant" ? (
            <li className={myLeaseType === "pending" ? "active" : ""}>
              <a
                href="#!"
                onClick={() => handleFilterLease("pending", "lease_tab_type")}
              >
                Pending <span>({tenant_pending_leases?.length})</span>
              </a>
            </li>
          ) : (
            ""
          )}
          {tenant_accepted_leases?.length > 0 && type === "tenant" ? (
            <li className={myLeaseType === "accepted" ? "active" : ""}>
              <a
                href="#!"
                onClick={() => handleFilterLease("accepted", "lease_tab_type")}
              >
                Active <span>({tenant_accepted_leases?.length})</span>
              </a>
            </li>
          ) : (
            ""
          )}
          {tenant_rejected_leases?.length > 0 && type === "tenant" ? (
            <li className={myLeaseType === "rejected" ? "active" : ""}>
              <a
                href="#!"
                onClick={() => handleFilterLease("rejected", "lease_tab_type")}
              >
                Declined <span>({tenant_rejected_leases?.length})</span>
              </a>
            </li>
          ) : (
            ""
          )}
          {tenant_expired_leases?.length > 0 && type === "tenant" ? (
            <li className={myLeaseType === "expired" ? "active" : ""}>
              <a
                href="#!"
                onClick={() => handleFilterLease("expired", "lease_tab_type")}
              >
                Expired <span>({tenant_expired_leases?.length})</span>
              </a>
            </li>
          ) : (
            ""
          )}
        </ul>
        <div className="filter_status_offer">
          {parseInt(count_landlord_leases) > 0 &&
          parseInt(count_tenant_leases) > 0 ? (
            <div className="col_ofer_filter">
              <div className="sorted_heading">
                <i className="fa fa-align-left"></i>
                <span>Role :</span>
              </div>
              <div className="sorted_drop_down">
                <select
                  name=""
                  id=""
                  className="txtBox"
                  onChange={(e) => handleFilterLease(e.target.value, "type")}
                >
                  {parseInt(pending_landlord_leases?.length) +
                    parseInt(accepted_landlord_leases?.length) +
                    parseInt(rejected_landlord_leases?.length) +
                    parseInt(expired_landlord_leases?.length) >
                  0 ? (
                    <>
                      <option
                        value="landlord"
                        selected={type == "landlord" ? true : false}
                      >
                        Landlord
                      </option>
                      <option
                        value="tenant"
                        selected={type == "tenant" ? true : false}
                      >
                        Tenant
                      </option>
                    </>
                  ) : parseInt(tenant_pending_leases?.length) +
                      parseInt(tenant_accepted_leases?.length) +
                      parseInt(tenant_rejected_leases?.length) +
                      parseInt(tenant_expired_leases?.length) >
                    0 ? (
                    <>
                      <option
                        value="tenant"
                        selected={type == "tenant" ? true : false}
                      >
                        Tenant
                      </option>
                      <option
                        value="landlord"
                        selected={type == "landlord" ? true : false}
                      >
                        Landlord
                      </option>
                    </>
                  ) : (
                    <>
                      <option
                        value="landlord"
                        selected={type == "landlord" ? true : false}
                      >
                        Landlord
                      </option>
                      <option
                        value="tenant"
                        selected={type == "tenant" ? true : false}
                      >
                        Tenant
                      </option>
                    </>
                  )}
                </select>
              </div>
            </div>
          ) : (
            ""
          )}
          {leases_filter === "member" ? (
            <div className="col_ofer_filter">
              <div className="sorted_heading">
                <span>Property :</span>
              </div>
              <div className="sorted_drop_down">
                <select
                  name=""
                  id=""
                  className="txtBox"
                  onChange={(e) =>
                    handleFilterLease(e.target.value, "property")
                  }
                >
                  <option value="">All</option>
                  {type === "landlord"
                    ? properties?.map((property, index) => {
                        return (
                          <option
                            value={property?.value}
                            key={index}
                            selected={
                              property?.value === propertyFilter ? true : false
                            }
                          >
                            {property?.label}
                          </option>
                        );
                      })
                    : type === "tenant"
                    ? tenantPropertiesArr?.map((tenant_property, index) => {
                        return (
                          <option
                            value={tenant_property?.value}
                            key={index}
                            selected={
                              tenant_property?.value === propertyFilter
                                ? true
                                : false
                            }
                          >
                            {tenant_property?.label}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>
            </div>
          ) : (
            <div className="col_ofer_filter">
              <div className="sorted_heading">
                <span>Branch :</span>
              </div>
              <div className="sorted_drop_down">
                <select
                  name=""
                  id=""
                  className="txtBox"
                  onChange={(e) => handleFilterLease(e.target.value, "branch")}
                >
                  <option value="">All</option>
                  {branches?.map((branch, index) => {
                    return (
                      <option
                        value={branch?.value}
                        key={index}
                        // selected={
                        //   branch?.value === propertyFilter ? true : false
                        // }
                      >
                        {branch?.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import { format_amount, format_date } from "../../../../helpers/api";

export const metadata = {
    title: 'Payments',
};

export default function PaymentTotalDetails({ lease_row }) {
    return (
        <>

            <div class="blk blockLst cell_block_cell custom_tbl_tbl">
                <table>
                    <thead>
                        <tr>
                            <th>Posted</th>
                            <th width="50%">Transaction</th>
                            <th width="10%">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lease_row?.payments?.length > 0 ?
                                lease_row?.payments?.map((payment, payment_index) => {
                                    return (
                                        <tr key={payment_index}>
                                            <td><span class="mini-show-cell">Date</span>{format_date(payment?.created_at)}</td>
                                            <td><span class="mini-show-cell">Transaction</span>{payment?.amount_name} {payment?.amount_name !== 'Security Deposit' ? payment?.rent_duration !== null && payment?.rent_duration !== '' && payment?.rent_duration !== undefined ? "(" + payment?.rent_duration + ")" : "" : ""}
                                                {/* {
                                                    payment?.amount_name === 'Credited Amount' ?
                                                        <span className="badge green">Credited Amount</span>
                                                        :
                                                        ""
                                                } */}
                                            </td>
                                            <td>
                                                <div className="flex credit_flex">
                                                    <span class="mini-show-cell">Amount</span>
                                                    {
                                                        payment?.amount_name === 'Credited Amount' ? <span className="credit_plus">+ {format_amount(payment?.amount)}</span> : format_amount(payment?.amount)

                                                    }
                                                    {
                                                        payment?.trans_status === 'pending' ?
                                                            <>
                                                                <br />
                                                                <span className="badge yellow">Pending from ACH</span>
                                                            </>
                                                            :
                                                            payment?.trans_status === 'created' ?
                                                                <>
                                                                    <br />
                                                                    <span className="badge green">Processed from ACH</span>
                                                                </>
                                                                :
                                                                // payment?.amount_name === 'Credited Amount' ?
                                                                //     <span className="badge green">Credited</span>
                                                                //     :
                                                                ""
                                                    }
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan="3"><div className="alert alert-danger">No data found!</div></td>
                                </tr>
                        }


                    </tbody>
                </table>
            </div>



        </>
    );
}
import React, { useEffect, useState } from "react";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  getPropertyAndOfferData,
  createPropertyLease,
} from "../../../states/actions/leases";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import LeaseStep1 from "./create-lease-sections/step1";
import LeaseStep2 from "./create-lease-sections/step2";
import Error from "../Error";
import toast from "react-hot-toast";
import {
  convertToDashesDate,
  format_date_simple,
  moment_new_date,
} from "../../../helpers/api";
import AddNewTenant from "./create-lease-sections/addNewTenant";
import { ToastContainer } from "react-toastify";
import moment from "moment";
export default function CreateLease({ mem_type }) {
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  const watchAllFields = watch();
  const [leasePdf, setLeasePdf] = useState({
    loading: false,
    file: null,
    file_name: null,
  });
  const [tenants, setTenants] = useState({
    tenants: [],
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lease_data = JSON.parse(
    decodeURIComponent(searchParams.get("lease_data"))
  );
  // const tenants_arr =
  //   lease_data?.tenants !== undefined &&
  //   lease_data?.tenants !== "" &&
  //   lease_data?.tenants !== null
  //     ? JSON.parse(lease_data?.tenants)
  //     : [];
  // useEffect(() => {
  //   if (tenants_arr?.length > 0) {
  //     setTenants({ ...tenants, tenants: tenants_arr });
  //   }
  // }, []);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.leases.content);
  const { property, offer, listing_row, property_obj } = data;
  const apis_tenants = data?.tenants_arr;
  const isLoading = useSelector((state) => state.leases.isPropertyOfferoading);
  const isLeaseFormLoading = useSelector(
    (state) => state.leases.isLeaseFormLoading
  );
  useEffect(() => {
    if (apis_tenants?.length > 0) {
      setTenants({ ...tenants, tenants: apis_tenants });
    }
  }, [apis_tenants]);
  useEffect(() => {
    dispatch(
      getPropertyAndOfferData({
        property: lease_data?.property,
        listing_id:
          lease_data?.listing_id !== undefined &&
          lease_data?.listing_id !== null &&
          lease_data?.listing_id !== ""
            ? lease_data?.listing_id
            : null,
        branch:
          lease_data?.branch !== undefined &&
          lease_data?.branch !== null &&
          lease_data?.branch !== ""
            ? lease_data?.branch
            : null,
        tenants: lease_data?.tenants,
      })
    );
  }, []);
  const [formStep, setFormStep] = useState(1);
  const [costsArr, setCosts] = useState({
    recurring_costs: [],
    one_time_costs: [],
    parking_costs: [],
  });
  useEffect(() => {
    const one_time_costs_arr = data?.one_time_costs;
    const recurring_costs_arr = data?.recurring_costs;
    const parking_costs_arr = data?.parking_costs;
    let updatedRecurringCosts = [];
    if (recurring_costs_arr?.length > 0) {
      updatedRecurringCosts = recurring_costs_arr?.map((cost) => ({
        ...cost,
        randomKey: Math.random(), // Generating a random key
        prevtCost: null,
      }));
    }
    let updatedOneTimeCosts = [];
    if (one_time_costs_arr?.length > 0) {
      updatedOneTimeCosts = one_time_costs_arr?.map((cost) => ({
        ...cost,
        randomKey: Math.random(), // Generating a random key
        prevtCost: null,
      }));
    }
    let updatedParkingCosts = [];
    if (parking_costs_arr?.length > 0) {
      updatedParkingCosts = parking_costs_arr?.map((cost) => ({
        ...cost,
        randomKey: Math.random(), // Generating a random key
        prevtCost: null,
      }));
    }

    setCosts({
      ...costsArr,
      recurring_costs: updatedRecurringCosts,
      one_time_costs: updatedOneTimeCosts,
      parking_costs: updatedParkingCosts,
    });
  }, [data]);
  const handleAddCosts = (cost_type = "recurring_costs") => {
    if (cost_type === "recurring_costs") {
      setCosts({
        ...costsArr,
        recurring_costs: costsArr.recurring_costs.concat([
          { randomKey: Math.random(), name: "", cost: "", prevtCost: null },
        ]),
      });
    } else if (cost_type === "parking_costs") {
      setCosts({
        ...costsArr,
        parking_costs: costsArr.parking_costs.concat([
          { randomKey: Math.random(), name: "", cost: "", prevtCost: null },
        ]),
      });
    } else {
      setCosts({
        ...costsArr,
        one_time_costs: costsArr.one_time_costs.concat([
          { randomKey: Math.random(), name: "", cost: "", prevtCost: null },
        ]),
      });
    }
  };

  function handleRemoveCost(idx, e, cost_type = "recurring_costs") {
    e.preventDefault();
    if (cost_type === "recurring_costs") {
      setCosts({
        ...costsArr,
        recurring_costs: costsArr.recurring_costs
          .slice(0, idx)
          .concat(
            costsArr.recurring_costs.slice(
              idx + 1,
              costsArr.recurring_costs.length
            )
          ),
      });
    } else if (cost_type === "parking_costs") {
      setCosts({
        ...costsArr,
        parking_costs: costsArr.parking_costs
          .slice(0, idx)
          .concat(
            costsArr.parking_costs.slice(idx + 1, costsArr.parking_costs.length)
          ),
      });
    } else {
      setCosts({
        ...costsArr,
        one_time_costs: costsArr.one_time_costs
          .slice(0, idx)
          .concat(
            costsArr.one_time_costs.slice(
              idx + 1,
              costsArr.one_time_costs.length
            )
          ),
      });
    }
  }
  const handleCostChange = (idx, evt, type = "recurring_costs") => {
    if (type === "recurring_costs") {
      const newCosts = costsArr.recurring_costs.map((cost, sidx) => {
        if (idx !== sidx) return cost;
        // return { ...tenant, [evt.target.name]: evt.target.value };
        if (evt.target.name === "email") {
          const inputValue = evt.target.value;
          const cleanValue = inputValue.replace(/\s/g, ""); // Remove all spaces
          evt.target.value = cleanValue;
          return { ...cost, [evt.target.name]: evt.target.value };
        } else {
          return { ...cost, [evt.target.name]: evt.target.value };
        }
      });
      setCosts({ ...costsArr, recurring_costs: newCosts });
    } else if (type === "parking_costs") {
      const newCosts = costsArr.parking_costs.map((cost, sidx) => {
        if (idx !== sidx) return cost;
        // return { ...tenant, [evt.target.name]: evt.target.value };
        if (evt.target.name === "email") {
          const inputValue = evt.target.value;
          const cleanValue = inputValue.replace(/\s/g, ""); // Remove all spaces
          evt.target.value = cleanValue;
          return { ...cost, [evt.target.name]: evt.target.value };
        } else {
          return { ...cost, [evt.target.name]: evt.target.value };
        }
      });
      setCosts({ ...costsArr, parking_costs: newCosts });
    } else {
      const newCosts = costsArr.one_time_costs.map((cost, sidx) => {
        if (idx !== sidx) return cost;
        // return { ...tenant, [evt.target.name]: evt.target.value };
        if (evt.target.name === "email") {
          const inputValue = evt.target.value;
          const cleanValue = inputValue.replace(/\s/g, ""); // Remove all spaces
          evt.target.value = cleanValue;
          return { ...cost, [evt.target.name]: evt.target.value };
        } else {
          return { ...cost, [evt.target.name]: evt.target.value };
        }
      });
      setCosts({ ...costsArr, one_time_costs: newCosts });
    }
  };
  const onSubmit = (frmData) => {
    if (leasePdf?.file === null) {
      toast.error("Please upload PDF to continue!");
      return;
    }
    if (
      frmData?.end_date &&
      frmData?.start_date &&
      moment(frmData?.end_date) < moment(frmData?.start_date)
    ) {
      toast.error("End date should be greater than start date");
      return;
    }
    if (tenants?.tenants?.length <= 0) {
      toast.error("Please add at least one tenant to continue.");
      return;
    }
    const newData = {
      ...frmData,
      property: property?.id,
      listing_id:
        listing_row?.id !== undefined &&
        listing_row?.id !== null &&
        listing_row?.id !== ""
          ? listing_row?.id
          : null,
      branch:
        lease_data?.branch !== undefined &&
        lease_data?.branch !== null &&
        lease_data?.branch !== ""
          ? lease_data?.branch
          : null,
      attachment: leasePdf?.file,
      tenants: JSON.stringify(tenants?.tenants),
      one_time_costs: JSON.stringify(costsArr?.one_time_costs),
      recurring_costs: JSON.stringify(costsArr?.recurring_costs),
      parking_costs: JSON.stringify(costsArr?.parking_costs),
      start_date: convertToDashesDate(frmData?.start_date, "YYYY/MM/DD"),
      end_date: convertToDashesDate(frmData?.end_date, "YYYY/MM/DD"),
      rent_due_date: convertToDashesDate(frmData?.rent_due_date, "YYYY/MM/DD"),
      promo_code: watchAllFields?.promo_code === true ? "yes" : "no",
    };
    dispatch(createPropertyLease(newData));
  };
  const [popup, setPopup] = useState(false);
  const togglePopup = (e) => {
    e.preventDefault();
    setPopup(!popup);
  };
  function emailExists(emailToCheck) {
    return tenants?.tenants.some((obj) => obj.email === emailToCheck);
  }
  const addNewTenant = (frmData) => {
    const exists = emailExists(frmData?.email);
    if (exists) {
      toast.error(frmData?.email + " already exists!");
      return;
    }
    setTenants({
      ...tenants,
      tenants: tenants.tenants.concat([
        { name: frmData?.name, email: frmData?.email },
      ]),
    });
    document.getElementById("tenant_name").value = "";
    document.getElementById("tenant_email").value = "";
    setPopup(false);
  };
  function handleRemoveTenant(idx, e) {
    e.preventDefault();
    setTenants({
      ...tenants,
      tenants: tenants.tenants
        .slice(0, idx)
        .concat(tenants.tenants.slice(idx + 1, tenants.tenants.length)),
    });
  }
  useDocumentTitle("Create Lease - Loftus");
  if (isLoading) return <LoadingScreen />;
  if (
    property?.id === undefined ||
    property?.id === null ||
    property?.id === "" ||
    property?.id <= 0
  ) {
    return <Error />;
  }
  // console.log(data);
  const promotion_row = data?.promotion_row;
  return (
    <>
      <main index className="main_dashboard height-100vh">
        <ToastContainer />
        <section className="dashboard">
          <div className="contain">
            <Breadcrumb
              page_name={"Create Lease"}
              parent_name="Leases"
              parent_link="/leases"
            />
            <div className="page_body">
              <div className="dash_form_blk">
                <form action="" method="post" onSubmit={handleSubmit(onSubmit)}>
                  {formStep === 1 ? (
                    <LeaseStep1
                      register={register}
                      errors={errors}
                      property={property}
                      offer={offer}
                      setValue={setValue}
                      setLeasePdf={setLeasePdf}
                      leasePdf={leasePdf}
                      control={control}
                      setFormStep={setFormStep}
                      trigger={trigger}
                      isValid={isValid}
                      handleAddCosts={handleAddCosts}
                      handleRemoveCost={handleRemoveCost}
                      handleCostChange={handleCostChange}
                      costsArr={costsArr}
                      tenants={tenants}
                      togglePopup={togglePopup}
                      handleRemoveTenant={handleRemoveTenant}
                      watchAllFields={watchAllFields}
                      property_obj={property_obj}
                      mem_type={mem_type}
                      promotion_row={promotion_row}
                      setCosts={setCosts}
                    />
                  ) : formStep === 2 ? (
                    <LeaseStep2
                      leasePdf={leasePdf}
                      setFormStep={setFormStep}
                      watchAllFields={watchAllFields}
                      tenants={tenants}
                      costsArr={costsArr}
                      isLeaseFormLoading={isLeaseFormLoading}
                      property={property_obj}
                      mem_type={mem_type}
                      promotion_row={promotion_row}
                    />
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>
        {popup === true ? (
          <AddNewTenant
            setPopup={setPopup}
            popup={popup}
            addNewTenant={addNewTenant}
          />
        ) : (
          ""
        )}
      </main>
    </>
  );
}

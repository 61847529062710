import {
    FETCH_OFFER_PROPERTY_CONTENT,
    FETCH_OFFER_PROPERTY_CONTENT_SUCCESS,
    FETCH_OFFER_PROPERTY_CONTENT_FAILED,
    SEND_OFFER_MESSAGE,
    SEND_OFFER_MESSAGE_SUCCESS,
    SEND_OFFER_MESSAGE_FAILED,
    SEND_OFFER_PAYPAL_MESSAGE,
    SEND_OFFER_PAYPAL_MESSAGE_SUCCESS,
    SEND_OFFER_PAYPAL_MESSAGE_FAILED,
    SEND_OFFER_STRIPE_MESSAGE,
    SEND_OFFER_STRIPE_MESSAGE_SUCCESS,
    SEND_OFFER_STRIPE_MESSAGE_FAILED,
    FETCH_SALE_OFFER_PROPERTY_CONTENT,
    FETCH_SALE_OFFER_PROPERTY_CONTENT_SUCCESS,
    FETCH_SALE_OFFER_PROPERTY_CONTENT_FAILED

} from "../actions/actionTypes";

const initialState = {
    isLoading: true,
    content: {},
    error: false,
    isFormProcessing: false,
    emptyFormData: false,
    questions: null,
    requestResponse: null,
};

export default function (state = initialState, { type, payload }) {

    switch (type) {
        case FETCH_OFFER_PROPERTY_CONTENT:
            return {
                ...state,
                isLoading: true,
                content: {},
            };
        case FETCH_OFFER_PROPERTY_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
            };
        case FETCH_OFFER_PROPERTY_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case FETCH_SALE_OFFER_PROPERTY_CONTENT:
            return {
                ...state,
                isLoading: true,
                content: {},
            };
        case FETCH_SALE_OFFER_PROPERTY_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
            };
        case FETCH_SALE_OFFER_PROPERTY_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case SEND_OFFER_MESSAGE:
            return {
                ...state,
                isFormProcessing: true,
            };
        case SEND_OFFER_MESSAGE_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                emptyFormData: true,
                questions: payload,
                requestResponse: null
            };
        case SEND_OFFER_MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                questions: null,
                requestResponse: payload
            };
        case SEND_OFFER_STRIPE_MESSAGE:
            return {
                ...state,
                isFormProcessing: true,
            };
        case SEND_OFFER_STRIPE_MESSAGE_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                emptyFormData: true,
            };
        case SEND_OFFER_STRIPE_MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                questions: null
            };
        case SEND_OFFER_PAYPAL_MESSAGE:
            return {
                ...state,
                isFormProcessing: true,
            };
        case SEND_OFFER_PAYPAL_MESSAGE_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                emptyFormData: true,
            };
        case SEND_OFFER_PAYPAL_MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                questions: null
            };



        default:
            return state;
    }
}

import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { format_amount, moment_new_date_for_edit } from '../../../helpers/api';
import moment from 'moment-timezone';
import 'moment-timezone';
export default function SendOfferStep2({ step, changeStep, register, errors, isValid, control, watchAllFields, trigger, listing_row, property_row, offer_row = false, isFormProcessing, package_verified, check_renter_verification_status, isProcessing, packageVerificationLoading, package_names, member_tu_verification_status }) {
    const [move_in_date, setMoveInDate] = useState(offer_row !== false && offer_row !== null && offer_row !== undefined && offer_row !== '' ? moment_new_date_for_edit(offer_row?.move_in_date) : null);
    return (
        <>
            <fieldset>
                <div className="medium_blk">
                    {/* <h4 className="ofer_heading">Offering</h4> */}
                    <div className="form_row row ">
                        {
                            property_row?.branch_flexible_lease !== undefined && property_row?.branch_flexible_lease !== null && property_row?.branch_flexible_lease === 'No' && property_row?.mem_type === 'company' ?
                                <>
                                    <div className='col-xs-8'>
                                        <h6>Price/Term</h6>
                                        <div className='form_blk deposit_form_blk'>
                                            <select name="term" id="term" className="txtBox branch_lease_txtBox" {...register("term", { required: "Required" })}>
                                                <option value=""></option>
                                                {
                                                    property_row?.prices_terms?.map((prices_term, index) => {
                                                        return <>

                                                            <option value={prices_term?.price + "-" + prices_term?.term} key={index} selected={offer_row?.price + "-" + offer_row?.term === prices_term?.price + "-" + prices_term?.term ? 'selected' : ""}>{format_amount(prices_term?.price) + " - " + prices_term?.term + " mth"}</option>
                                                        </>
                                                    })
                                                }

                                            </select>
                                            {/* <span className='dollar_label branch_lease_label'>mth</span> */}
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-xs-4">
                                        <h6>Price</h6>
                                        <div className="form_blk deposit_form_blk">
                                            <input type="text" name="price" id="price" className="txtBox"
                                                {...register("price", {
                                                    required: "Required", pattern: {
                                                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                                        message: "Only numbers are allowed!"
                                                    }
                                                })} defaultValue={offer_row !== false && offer_row !== null && offer_row !== undefined && offer_row !== '' ? offer_row?.price : ""} />
                                            <span className='dollar_label'>$</span>
                                            <ErrorMessage
                                                errors={errors}
                                                name="price"
                                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xs-4">
                                        <h6>Term</h6>
                                        <div className="form_blk deposit_form_blk">
                                            <input type="text" name="term" id="term" className="txtBox" {...register("term", {
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: 'Invalid value',
                                                },
                                                required: "Required"
                                            })} defaultValue={offer_row !== false && offer_row !== null && offer_row !== undefined && offer_row !== '' ? offer_row?.term : ""} />
                                            <span className='dollar_label'>mth</span>

                                            <ErrorMessage
                                                errors={errors}
                                                name="term"
                                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                            />

                                        </div>
                                    </div>
                                </>
                        }
                        <div className="col-xs-4">
                            <h6>Move-in Date</h6>
                            <div className="form_blk">
                                <Controller
                                    control={control}
                                    name="move_in_date"
                                    {...register("move_in_date", { required: "Required" })}
                                    defaultValue={move_in_date !== null && move_in_date !== '' && move_in_date !== undefined ? move_in_date : null}
                                    render={({ field }) => (
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            className="txtBox"
                                            placeholderText="_ _ / _ _ / _ _ _ _"
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value !== null && field.value !== '' && field.value !== undefined ? new Date(field.value) : null}
                                            minDate={moment_new_date_for_edit(property_row?.available_date)}
                                        />
                                    )}
                                />
                                {/* <InputMask mask="99-99-9999" name="move_in_date" id="move_in_date" className='txtBox' autoComplete="none"  {...register("move_in_date", {
                                    required: "Required"
                                })} defaultValue={watchAllFields?.move_in_date} /> */}
                                <ErrorMessage
                                    errors={errors}
                                    name="move_in_date"
                                    render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bTn cmnBtn text-center offering_send_btns">
                        <button type="button" className="webBtn backBtn" onClick={() => changeStep(step - 1)}>Previous</button>
                        {
                            !isValid ?
                                <button type="button" className="webBtn colorBtn nextBtn" onClick={() => {
                                    trigger();
                                }} >{offer_row !== false && offer_row !== null && offer_row !== undefined && offer_row !== '' ? 'Update' : 'Next'}</button>
                                :
                                member_tu_verification_status === 'manual' ?
                                    <button type="button" className="webBtn colorBtn nextBtn" onClick={() => changeStep(6)} disabled={!isValid}>Next</button>
                                    :
                                    package_verified === true ?
                                        <button type="button" className="webBtn colorBtn nextBtn" onClick={() => check_renter_verification_status()} disabled={!isValid || packageVerificationLoading ||
                                            isProcessing || isFormProcessing}>Next {packageVerificationLoading || isProcessing || isFormProcessing ? <i className='spinner'></i> : ""}</button>

                                        :
                                        offer_row !== false && offer_row !== null && offer_row !== undefined && offer_row !== '' ?
                                            <button type="submit" className="webBtn colorBtn nextBtn" disabled={!isValid || isFormProcessing}>Update {isFormProcessing ? <i className='spinner'></i> : ""}</button>
                                            :
                                            <button type="button" className="webBtn colorBtn nextBtn" onClick={() => changeStep(step + 1)} disabled={!isValid}>Next</button>

                        }
                    </div>
                </div>
            </fieldset>
        </>
    )
}

import React, { useEffect, useState } from "react";
import Breadcrumb from "./sections/breadcrumb";
import MaintenanceBlk from "./maintenance_team/blk";

import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import {
  fetchMaintenanceStaff,
  deletePerson,
} from "../../../states/actions/fetchMaintenanceStaff";
import { consoleLog } from "../../../helpers/api";
import { ToastContainer } from "react-toastify";
import DetailPopup from "./maintenance_profile/detail-popup";
export default function MaintenceTeam() {
  const [detailPopup, setDetailPopup] = useState(null);
  const [maintenanceStaffArray, setMaintenanceStaffArray] = useState([]);
  const openDetailPopup = (item) => {
    setDetailPopup(item);
  };
  const closeDetailPopup = () => {
    setDetailPopup(null);
  };

  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchMaintenanceStaff.content);
  const isLoading = useSelector(
    (state) => state.fetchMaintenanceStaff.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchMaintenanceStaff.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchMaintenanceStaff());
  }, []);
  useEffect(() => {
    setMaintenanceStaffArray(data?.maintenance_staff);
  }, [data?.maintenance_staff]);

  const handleDelete = (e, id) => {
    e.preventDefault();
    dispatch(deletePerson(id));
  };
  useDocumentTitle("Service Staff", "Loftus");
  if (isLoading) return <LoadingScreen />;
  const maintenance_staff = data?.maintenance_staff;
  const properties = data?.properties;
  const bank_accounts = data?.bank_accounts;
  console.log(maintenanceStaffArray);
  return (
    <>
      <ToastContainer />
      <main index className="main_dashboard">
        <section className="dashboard">
          <div className="contain">
            <Breadcrumb
              page_name="Service Staff"
              add_link="/add-service-person"
              // popup_show={true}
              // popup_name="create-new-staff"
              // staff_properties={properties}
            />
            <div className="page_body">
              <div className="maintenence_list_team">
                <div className="flex">
                  {isFormProcessing ? (
                    <div className="loadingDisabled">
                      <i className="spinner"></i>
                    </div>
                  ) : (
                    ""
                  )}
                  <MaintenanceBlk
                    items={maintenanceStaffArray}
                    handleDelete={handleDelete}
                    staff_properties={properties}
                    openDetailPopup={openDetailPopup}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {detailPopup !== null ? (
        <DetailPopup
          item={detailPopup}
          closeDetailPopup={closeDetailPopup}
          staff_properties={detailPopup?.staff_properties}
          setMaintenanceStaffArray={setMaintenanceStaffArray}
          openDetailPopup={openDetailPopup}
          detailPopupItem={detailPopup}
          handleDelete={handleDelete}
          isFormProcessing={isFormProcessing}
          bank_accounts={bank_accounts}
        />
      ) : (
        ""
      )}
    </>
  );
}

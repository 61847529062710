import React, { useEffect, useState } from "react";
import { getServerImage } from "../../../../helpers/api";
import { useNavigate } from "react-router-dom";
import http from "../../../../helpers/http";
import * as helpers from "../../../../helpers/api";
import Text from "../../../common/Text";
import toast from "react-hot-toast";
import StartChat from "./startChat";
export default function AssignedStaffMembers({
  staffMembers,
  request_id,
  type,
  assigned_staff_members,
  reqStatus,
  setReqStatus,
  is_landlord,
  is_landlord_staff,
  mem_type,
  setCommercialStaffAssigned,
  isCommericlalStaffAssigned,
}) {
  const [assignedStaff, setAssignedStaff] = useState([]);

  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const navigate = useNavigate();
  const toggleStaffSelection = (staffId) => {
    console.log(staffId);
    if (reqStatus !== "completed") {
      if (staffId) {
        if (mem_type === "member") {
          setAssignedStaff(
            (prevAssignedStaff) =>
              prevAssignedStaff.includes(staffId)
                ? [] // If the item is already in the array, remove it (resulting in an empty array)
                : [staffId] // Otherwise, replace the array with the new item
          );
        } else {
          setAssignedStaff((prevAssignedStaff) =>
            prevAssignedStaff.includes(staffId)
              ? prevAssignedStaff.filter((id) => id !== staffId)
              : [...prevAssignedStaff, staffId]
          );
        }
      }
    }
  };
  useEffect(() => {
    if (assigned_staff_members?.length > 0) {
      setAssignedStaff(assigned_staff_members);
    }
  }, [assigned_staff_members]);
  const handleAssign = (e) => {
    e.preventDefault();
    if (is_landlord) {
      if (mem_type === "member") {
        if (
          assignedStaff !== null &&
          assignedStaff !== undefined &&
          assignedStaff !== "" &&
          assignedStaff?.length > 1
        ) {
          toast.error(
            "Only one staff member can be added for single Service Request!"
          );
          return;
        }
      }
      // if (
      //   assignedStaff !== null &&
      //   assignedStaff !== undefined &&
      //   assignedStaff !== "" &&
      //   assignedStaff?.length > 0
      // ) {
      setIsFormProcessing(true);
      http
        .post(
          "assign-staff-maintenance-request",
          helpers.doObjToFormData({
            request_id: request_id,
            staff_ids: JSON.stringify(assignedStaff),
            mem_type: mem_type,
          })
        )
        .then(({ data }) => {
          setIsFormProcessing(false);
          if (data.status) {
            if (
              data?.mem_type === "company" &&
              data?.commercial_assigned === 1
            ) {
              setCommercialStaffAssigned(true);
            }
            toast.success(data?.msg);
            setReqStatus(data?.maintenance_status);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            toast.error(<Text string={data.msg} parse={true} />);
          }
        })
        .catch((error) => {
          setIsFormProcessing(false);
          toast.error(error);
          return;
        });
      // } else {
      //   setIsFormProcessing(false);
      //   toast.error("Please select a staff member first to proceed!");
      //   return;
      // }
    }
  };
  console.log(assignedStaff);
  return staffMembers?.length > 0 ? (
    <>
      {parseInt(is_landlord_staff) !== 1 ? (
        <h4 className="ofer_heading">Assign To</h4>
      ) : (
        <h4 className="ofer_heading">Assigned To</h4>
      )}

      <div className="maint_persons_outer">
        <form action="">
          <div className="flex_maint_persons">
            {staffMembers?.length > 0 ? (
              staffMembers?.map((staffMember, index) => {
                return (
                  <div className="col" key={index}>
                    <div
                      className={
                        assignedStaff?.includes(
                          staffMember?.id
                            ? staffMember?.id
                            : staffMember?.user_id
                        )
                          ? "_innercol active relative"
                          : "_innercol relative"
                      }
                      onClick={() =>
                        toggleStaffSelection(
                          staffMember?.id
                            ? staffMember?.id
                            : staffMember?.user_id
                        )
                      }
                    >
                      <div className="mini_icon">
                        <img
                          src={getServerImage(
                            "/storage/members/",
                            staffMember?.user_dp
                          )}
                          alt={staffMember?.user_name}
                        />
                      </div>
                      <h4>{staffMember?.user_name}</h4>
                      {type !== "assigned" &&
                      parseInt(staffMember?.is_landlord) !== 1 ? (
                        <StartChat tenant_id={staffMember?.user_id} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                className="alert alert-danger"
                style={{ width: "50%", margin: "0 auto" }}
              >
                No staff members have been added. Please add members in the
                Service Staff module to delegate the request.
              </div>
            )}
          </div>
          <div className="gap_line_divide"></div>
          {parseInt(is_landlord_staff) !== 1 &&
          parseInt(is_landlord) === 1 &&
          reqStatus !== "completed" &&
          reqStatus !== "finished" &&
          staffMembers?.length > 0 ? (
            <div className="text-center">
              <button
                className="webBtn"
                type="button"
                onClick={handleAssign}
                disabled={isFormProcessing}
              >
                Assign {isFormProcessing ? <i className="spinner"></i> : ""}
              </button>
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
      {/* <div className="gap_line_divide"></div>
      <div className="bTn offer_cstm_ul offer_action_btn">
        <a href="#!" className="webBtn">
          Assign
        </a>
      </div> */}
    </>
  ) : mem_type === "company" ? (
    <div
      className="alert alert-danger"
      style={{ width: "50%", margin: "0 auto" }}
    >
      No employees have been added. Please add employees to delegate the
      request.
    </div>
  ) : (
    <div
      className="alert alert-danger"
      style={{ width: "50%", margin: "0 auto" }}
    >
      No staff members have been added. Please add members in the maintenance
      staff module to delegate the request.
    </div>
  );
}
